import ChatChannelSettingsDrawer from "@/apps/list/app/chat/ChatChannelSettingsDrawer"
import { GlobalID } from "@/relay/RelayTypes"
import UserAvatarStack, {
  CountVariants,
  UserAvatarStackProps,
} from "@/user/common/avatar-stack/UserAvatarStack"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import OpenChatChannelProfileListButton from "@components/chat/channel/detail/avatar-stack/OpenChatChannelProfileListDrawerButton"
import { useState } from "react"

type ChatChannelAvatarStackProps = Pick<
  UserAvatarStackProps,
  "users" | "totalUsers" | "avatarSize" | "className"
> & {
  channelId?: GlobalID
  channelAppId?: GlobalID
  countVariant?: CountVariants
  listOnly?: boolean
}

function ChatChannelAvatarStack({
  users,
  totalUsers,
  avatarSize = 24,
  className,
  channelId,
  channelAppId,
  countVariant,
  listOnly,
}: ChatChannelAvatarStackProps) {
  const [isOpen, setIsOpen] = useState(false)
  const singleUserId = users.length === 1 && users[0]?.id

  return (
    <>
      {singleUserId && !listOnly ? (
        <OpenProfilePopoverButton stopPropagation userId={singleUserId}>
          {(profileButtonProps) => (
            <UserAvatarStack
              testid={"ChatChannelAvatarStack"}
              users={users}
              className={className}
              avatarSize={avatarSize}
              onClick={profileButtonProps.onClick}
              onClickAddUser={channelAppId ? () => setIsOpen(true) : undefined}
              viewUserTooltip={{
                content: `${users[0].first_name} ${users[0].last_name}`,
                dataPrivate: true,
              }}
              stackSize={1}
            />
          )}
        </OpenProfilePopoverButton>
      ) : channelId ? (
        <OpenChatChannelProfileListButton channelId={channelId}>
          {(profileListProps) => (
            <UserAvatarStack
              testid={"ChatChannelAvatarStack"}
              users={users}
              totalUsers={totalUsers}
              className={className}
              avatarSize={avatarSize}
              onClick={profileListProps.onClick}
              onClickAddUser={channelAppId ? () => setIsOpen(true) : undefined}
              countVariant={countVariant}
            />
          )}
        </OpenChatChannelProfileListButton>
      ) : null}
      {channelAppId && (
        <ChatChannelSettingsDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          appId={channelAppId}
        />
      )}
    </>
  )
}

export default ChatChannelAvatarStack
