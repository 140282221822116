import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import ChatEmoji from "@components/chat/channel/emoji-button/ChatEmoji"
import { DiscoButton, DiscoText, DiscoTooltip } from "@disco-ui"
import { darken, lighten } from "@material-ui/core"
import { BaseEmoji } from "emoji-mart"
import React, { FC, Suspense } from "react"
import { ReactionResponse } from "stream-chat"
import { useChatContext, useEmojiContext, useMessageContext } from "stream-chat-react"

interface IChatChannelEmojiButton {
  emoji: string | BaseEmoji
  variant?: "options" | "reaction-list"
  size?: number
  reactionCount?: number
  reacted?: boolean
  reactions?: ReactionResponse[]
  loadReactions?: () => Promise<void>
}

const ChatChannelEmojiButton: FC<IChatChannelEmojiButton> = ({
  emoji,
  variant = "options",
  size = variant === "options" ? 20 : 16,
  reactionCount = 0,
  reacted = false,
  reactions = [],
  loadReactions,
}) => {
  const { handleReaction } = useMessageContext()
  const { emojiConfig } = useEmojiContext()
  const { client: streamClient } = useChatContext()
  const classes = useStyles({ size, reacted, variant, reactionCount })
  const emojiName = `:${typeof emoji === "string" ? emoji : emoji.name}:`
  const usersReacted = getUsersReacted()

  return (
    <DiscoTooltip
      content={
        usersReacted ? (
          <div className={classes.innerTooltip}>
            <div className={classes.emojiWrapper}>
              <ChatEmoji data={emojiConfig.emojiData} emoji={emoji} size={40} />
            </div>
            <DiscoText variant={"body-sm"} color={"common.white"}>
              {`${usersReacted} reacted with ${emojiName}`}
            </DiscoText>
          </div>
        ) : (
          emojiName
        )
      }
    >
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div className={classes.buttonWrapper} onMouseOver={handleTooltipOpen}>
        <DiscoButton
          onClick={handleEmojiClick}
          classes={{
            root: classes.button,
            leftIcon: classes.leftIcon,
          }}
          color={"transparent"}
          leftIcon={
            <Suspense fallback={null}>
              <ChatEmoji data={emojiConfig.emojiData} emoji={emoji} size={size} />
            </Suspense>
          }
        >
          {reactionCount > 0 && (
            <DiscoText className={classes.reactionCount} variant={"body-sm"}>
              {reactionCount}
            </DiscoText>
          )}
        </DiscoButton>
      </div>
    </DiscoTooltip>
  )

  function handleEmojiClick(evt: React.MouseEvent) {
    handleReaction(typeof emoji === "string" ? emoji : emoji.id, evt)
  }

  function handleTooltipOpen() {
    if (loadReactions && reactionCount !== reactions.length) loadReactions()
  }

  function getUsersReacted() {
    let text = ""
    for (let i = 0; i < reactions.length; i++) {
      if (reactions[i].user?.name) {
        if (i > 0) text += i === reactions.length - 1 ? " and " : ", "
        const { name, id } = reactions[i].user!
        if (id === streamClient.user?.id) {
          text += i > 0 ? "you" : "You"
        } else {
          text += name
        }
      }
    }
    return text
  }
}

interface StyleProps {
  size: number
  reacted: boolean
  variant: "options" | "reaction-list"
  reactionCount?: number
}

const useStyles = makeUseStyles((theme) => ({
  reactionCount: (props: StyleProps) => ({
    display: "inline-block",
    fontWeight: 500,
    color: props.reacted
      ? theme.palette.type === "light"
        ? lighten(theme.palette.primary.main, 0.2)
        : theme.palette.common.white
      : "currentColor",
  }),
  button: (props: StyleProps) => ({
    width: props.variant === "options" ? "18px" : "22px",
    height: props.variant === "options" ? "18px" : "18px",
    backgroundColor: "transparent",
  }),
  buttonWrapper: (props: StyleProps) => ({
    display: "inline-flex",
    alignItems: "center",
    boxSizing: "border-box",
    border: `2px solid ${
      props.reacted ? lighten(theme.palette.primary.main, 0.2) : "transparent"
    }`,
    backgroundColor: props.reacted
      ? theme.palette.type === "light"
        ? lighten(theme.palette.primary.main, 0.9)
        : darken(theme.palette.primary.main, 0.6)
      : theme.palette.groovy.neutral[200],
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.measure.borderRadius.xl,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: props.reacted
        ? theme.palette.type === "light"
          ? lighten(theme.palette.primary.main, 0.7)
          : darken(theme.palette.primary.main, 0.3)
        : theme.palette.groovy.neutral[100],
    },
    ...styleIf(props.variant === "options", {
      padding: theme.spacing(0.75, 0.25),
      borderRadius: "50%",
      backgroundColor: props.reacted
        ? lighten(theme.palette.primary.main, 0.2)
        : theme.palette.background.paper,
    }),
  }),
  leftIcon: (props: StyleProps) => ({
    height: 18,
    marginRight: props.reactionCount ? theme.spacing(0.5) : 0,
  }),
  innerTooltip: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
  },
  emojiWrapper: {
    backgroundColor: theme.palette.common.white,
    display: "inline-block",
    borderRadius: theme.measure.borderRadius.default,
    height: "40px", // Fix ChatEmoji's outer span being too tall
  },
}))

export default React.memo(ChatChannelEmojiButton, (prev, next) => {
  if (typeof prev.emoji !== typeof next.emoji) return false
  if (typeof prev.emoji !== "string" && typeof next.emoji !== "string") {
    if (prev.emoji.id !== next.emoji.id) return false
  } else if (prev.emoji !== next.emoji) {
    return false
  }
  return (
    prev.variant === next.variant &&
    prev.size === next.size &&
    prev.reactionCount === next.reactionCount &&
    prev.reacted === next.reacted &&
    prev.reactions?.length === next.reactions?.length
  )
})
