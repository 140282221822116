/**
 * @generated SignedSource<<4af4d58b6570e5f877a2f12cc77dbc45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation$variables = {
  id: string;
};
export type ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation$data = {
  readonly dismissBotSuggestion: {
    readonly node: {
      readonly id: string;
    } | null;
  };
};
export type ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation = {
  variables: ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation$variables;
  response: ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissBotSuggestionResponse",
        "kind": "LinkedField",
        "name": "dismissBotSuggestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BotQueuedMessage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissBotSuggestionResponse",
        "kind": "LinkedField",
        "name": "dismissBotSuggestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BotQueuedMessage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0e42c3dfb9699b7ecb15e296fc5cc0c",
    "id": null,
    "metadata": {},
    "name": "ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation",
    "operationKind": "mutation",
    "text": "mutation ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation(\n  $id: ID!\n) {\n  dismissBotSuggestion(id: $id) {\n    node {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfb92a5abac113bd7adf96b7786d7c07";

export default node;
