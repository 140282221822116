import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelMessageRepliesCountButton from "@components/chat/channel/replies/ChatChannelMessageRepliesCountButton"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { StreamMessage } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

interface Props {
  message: StreamMessage<DefaultStreamChatGenerics>
  threadList: boolean | undefined
  handleReply: (event: React.MouseEvent<Element, MouseEvent>) => void
}

function ChatChannelDeletedMessage({ message, threadList, handleReply }: Props) {
  const classes = useStyles({ hasThread: !threadList && !!message.reply_count })
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <div className={classes.messagePlaceholder}>
        <div className={classes.avatarPlaceholder}>
          <DiscoIcon width={45} icon={"trash"} />
        </div>
        <div>
          <DiscoText
            color={
              theme.palette.type === "dark" ? "primary.contrastText" : "groovy.grey.600"
            }
          >
            {"This message was deleted."}
          </DiscoText>
          {!threadList && !!message.reply_count && (
            <div className={classes.replyContainer}>
              <ChatChannelMessageRepliesCountButton
                onClick={(e) => handleReply(e)}
                reply_count={message.reply_count}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface StyleProps {
  hasThread: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(1.5, 0, 1.5, 0),
  },
  messagePlaceholder: ({ hasThread }: StyleProps) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: hasThread ? "start" : "center",
    gap: theme.spacing(1.5),
    fontSize: "15px",
  }),
  avatarPlaceholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45px",
    maxHeight: "100%",
    borderRadius: "15px",
    backgroundColor: theme.palette.grey["50"],
    dropShadow: {
      boxShadow: theme.palette.groovyDepths.insideCard,
    },
  },
  replyContainer: {
    marginBottom: theme.spacing(2),
  },
}))

export default ChatChannelDeletedMessage
