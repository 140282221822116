/**
 * @generated SignedSource<<f32afbda121f061356b6318b56752668>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunitySwitcherListItemFragment$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly badge: {
      readonly id: string;
      readonly kind: BadgeKind;
      readonly icon: string | null;
      readonly color: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
    readonly primaryDomain: string;
    readonly " $fragmentSpreads": FragmentRefs<"CommunityBadgeFragment">;
  };
  readonly " $fragmentType": "CommunitySwitcherListItemFragment";
};
export type CommunitySwitcherListItemFragment$key = {
  readonly " $data"?: CommunitySwitcherListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunitySwitcherListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunitySwitcherListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Badge",
          "kind": "LinkedField",
          "name": "badge",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "icon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BadgeFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryDomain",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommunityBadgeFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "a30390d6262f16bb226f8f50341d7909";

export default node;
