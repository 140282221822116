import ReplyIcon from "@/core/ui/iconsax/linear/custom-reply.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import pluralize from "pluralize"

type Props = {
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
  reply_count?: number
}

const ChatChannelMessageRepliesCountButton = ({ onClick, reply_count }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DiscoButton
        onClick={onClick}
        color={"transparent"}
        className={classes.reply}
        leftIcon={<ReplyIcon />}
      >
        <DiscoText
          variant={"body-sm-700"}
          color={"primary.main"}
        >{`${reply_count} ${pluralize("reply", reply_count)}`}</DiscoText>
      </DiscoButton>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0.5),
  },
  reply: {
    padding: 0,
    height: "unset",

    "& svg": {
      color: `${theme.palette.groovy.grey[400]} !important`,
      width: "20px",
      height: "20px",
    },
  },
}))

export default ChatChannelMessageRepliesCountButton
