import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import Box from "@material-ui/core/Box"
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress"

type CircularProgressWithLabelProps = Omit<CircularProgressProps, "color"> & {
  value: number
  icon?: React.ReactNode
  color?: string
  className?: string
  showBuffer?: boolean
  bufferValue?: number
  fontColor?: string
  fontSize?: number
}

function CircularProgressWithLabel({
  value,
  icon,
  color,
  className,
  showBuffer = false,
  bufferValue = 100,
  fontColor = color,
  fontSize = 12,
  ...progressProps
}: CircularProgressWithLabelProps) {
  const classes = useStyles({ fontSize, fontColor })

  return (
    <Box
      position={"relative"}
      display={"inline-flex"}
      color={color}
      className={className}
    >
      {showBuffer && (
        <CircularProgress
          variant={"determinate"}
          {...progressProps}
          value={bufferValue}
          className={classes.buffer}
          color={"secondary"}
        />
      )}

      <CircularProgress
        color={"inherit"}
        variant={"determinate"}
        value={value}
        {...progressProps}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position={"absolute"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {icon ? (
          <>{icon}</>
        ) : (
          <DiscoText setColor={fontColor} className={classes.label}>{`${Math.round(
            value
          )}%`}</DiscoText>
        )}
      </Box>
    </Box>
  )
}

type StyleProps = {
  fontSize: number
  fontColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  label: ({ fontSize, fontColor }: StyleProps) => ({
    fontSize: `${fontSize}px`,
    color: fontColor,
  }),
  buffer: {
    color: theme.palette.groovy.neutral[300],
    position: "absolute",
  },
}))

export default CircularProgressWithLabel
