/**
 * @generated SignedSource<<5923df4b4c017ea7ceb3b23ae6280f6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OccurrenceStatus = "draft" | "published" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RSVPToEventButtonFragment$data = {
  readonly id: string;
  readonly status: OccurrenceStatus;
  readonly hasViewerRSVPd: boolean;
  readonly capacity: number | null;
  readonly atCapacity: boolean;
  readonly event: {
    readonly id: string;
    readonly isRecurring: boolean;
  };
  readonly " $fragmentType": "RSVPToEventButtonFragment";
};
export type RSVPToEventButtonFragment$key = {
  readonly " $data"?: RSVPToEventButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RSVPToEventButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RSVPToEventButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerRSVPd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "atCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRecurring",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "3ba45c05c277b2b06a8916c009ef83a5";

export default node;
