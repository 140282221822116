/**
 * @generated SignedSource<<4d32e692ca3483294ebcc2bd995cfabb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatChannelKind = "default" | "custom" | "direct_message" | "chat_bot" | "%future added value";
export type ChatChannelVisibility = "public" | "private" | "%future added value";
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatChannelHeaderFragment$data = {
  readonly id: string;
  readonly kind: ChatChannelKind;
  readonly visibility: ChatChannelVisibility;
  readonly product: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
  } | null;
  readonly app: {
    readonly id: string;
    readonly customAppTitle: string | null;
    readonly customAppDescription: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"HeaderContentFragment" | "ChatChannelSettingsModalButtonFragment" | "ChatChannelBadgeFragment" | "DeleteChatChannelAppButtonFragment">;
  } | null;
  readonly chatChannelMembers: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
          readonly avatar: string;
        };
        readonly productMembership: {
          readonly role: ProductRole;
        } | null;
        readonly organizationMembership: {
          readonly role: OrganizationRole;
        };
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "ChatChannelHeaderFragment";
};
export type ChatChannelHeaderFragment$key = {
  readonly " $data"?: ChatChannelHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelHeaderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppDescription",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeaderContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChatChannelSettingsModalButtonFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChatChannelBadgeFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeleteChatChannelAppButtonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "concreteType": "ChatChannelMemberNodeConnection",
      "kind": "LinkedField",
      "name": "chatChannelMembers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelMemberNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannelMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductMembership",
                  "kind": "LinkedField",
                  "name": "productMembership",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationMembership",
                  "kind": "LinkedField",
                  "name": "organizationMembership",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "chatChannelMembers(first:5)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};
})();

(node as any).hash = "c611da681855f39f681ef6efa8d7f289";

export default node;
