import UserAvatar, { hasUserAvatarShape } from "@/user/common/avatar/UserAvatar"
import { useMessageContext } from "stream-chat-react"

const ChatChannelAvatar = () => {
  const { message } = useMessageContext()
  const { user } = message

  if (!hasUserAvatarShape(user)) return null

  return (
    <UserAvatar
      className={"str-chat__avatar"}
      placeholderClassName={"str-chat__avatar"}
      user={user}
      testid={"avatar"}
    />
  )
}

export default ChatChannelAvatar
