/**
 * @generated SignedSource<<04bb49c8342a780191479217fae6faa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannel_EmptyStateIndicatorFragment">;
  readonly " $fragmentType": "ChatChannelFragment";
};
export type ChatChannelFragment$key = {
  readonly " $data"?: ChatChannelFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannel_EmptyStateIndicatorFragment"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};

(node as any).hash = "020f9ae497113860830312703c5902fa";

export default node;
