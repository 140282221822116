import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { formatDurationSeconds } from "@utils/time/timeUtils"
import classNames from "classnames"
import React, { ReactNode } from "react"
import PlayArrowIcon from "../../core/ui/iconsax/bold/play-circle.svg"
import { COVER_PHOTO_PADDING_TOP } from "../../utils/image/imageConstants"

interface CoverPhotoProps {
  coverPhoto: string | null
  videoDuration?: number | null
  customClassName?: string
  children?: React.ReactNode
  testid?: string
  showCoverPlayIcon?: boolean
  borderBottomLeftRadius?: number
  borderBottomRightRadius?: number
  actionButtons?: ReactNode
  aspectRatio?: number
  loadingBackground?: boolean
  isGridItem?: boolean
}

function CoverPhoto({
  coverPhoto,
  videoDuration,
  customClassName,
  children,
  testid,
  showCoverPlayIcon = false,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  actionButtons,
  aspectRatio,
  loadingBackground = false,
  isGridItem = false,
}: CoverPhotoProps) {
  const classes = useStyles({
    borderBottomLeftRadius,
    borderBottomRightRadius,
    aspectRatio,
    loadingBackground,
    isGridItem,
  })

  const duration = videoDuration && formatDurationSeconds(videoDuration)

  return (
    <div
      data-testid={testid}
      data-photo={coverPhoto}
      className={classNames(classes.container, customClassName)}
      style={{
        backgroundImage: getBackgroundImageSrc(),
      }}
    >
      {/* Display video duration */}
      {duration && (
        <DiscoText className={classes.videoDurationContainer} variant={"body-sm"}>
          {duration}
        </DiscoText>
      )}
      {actionButtons && (
        <div className={classes.actionButtonsOverlay}>{actionButtons}</div>
      )}
      {children}
      {showCoverPlayIcon && (
        <div className={classes.thumbnailPlayIcon}>
          <PlayArrowIcon />
        </div>
      )}
    </div>
  )

  function getBackgroundImageSrc() {
    if (!coverPhoto) return undefined
    return `url(${coverPhoto})`
  }
}

interface StyleProps {
  borderBottomLeftRadius?: number
  borderBottomRightRadius?: number
  aspectRatio?: number
  loadingBackground?: boolean
  isGridItem?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({
    borderBottomLeftRadius,
    borderBottomRightRadius,
    aspectRatio,
    loadingBackground,
    isGridItem,
  }: StyleProps) => ({
    borderTopLeftRadius: theme.measure.borderRadius.big,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: borderBottomRightRadius ?? theme.measure.borderRadius.big,
    borderBottomLeftRadius: borderBottomLeftRadius ?? theme.measure.borderRadius.big,
    position: "relative",
    paddingTop:
      aspectRatio === undefined ? COVER_PHOTO_PADDING_TOP : `${100 / aspectRatio}%`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: isGridItem ? "cover" : "contain",
    backgroundColor: loadingBackground ? theme.palette.groovy.grey[100] : undefined,
  }),
  videoDurationContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: "3px",
    margin: "3px",
    backgroundColor: theme.palette.groovy.grey[700],
    color: "white",
    borderRadius: theme.measure.borderRadius.big,
  },
  thumbnailPlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& svg > circle": {
      color: theme.palette.groovy.blue[400],
    },
  },
  actionButtonsOverlay: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateY(-50%) translateX(50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
    zIndex: theme.zIndex.raise2,
  },
}))

export const CoverPhotoSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  const theme = useTheme()

  return (
    <Skeleton
      className={className}
      variant={"rect"}
      style={{
        width: "100%",
        paddingTop: COVER_PHOTO_PADDING_TOP,
        borderRadius: theme.measure.borderRadius.medium,
      }}
    />
  )
}

export default CoverPhoto
