import ChatBotAvatar from "@/admin/integrations/chat-bot/ChatBotAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInput, DiscoInputProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { ForwardedRef } from "react"

type AIInputProps = Omit<
  DiscoInputProps,
  "classes" | "minHeight" | "padding" | "autoFocus" | "multiline" | "startAdornment"
> &
  TestIDProps & {
    showBadge?: boolean
  }

const AIInput = (
  { showBadge = true, testid, ...rest }: AIInputProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>
) => {
  const classes = useStyles()

  return (
    <DiscoInput
      ref={ref}
      {...rest}
      classes={{ root: classes.aiInput }}
      data-testid={testid}
      minHeight={"24px"}
      padding={"10px 12px"}
      multiline
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      startAdornment={
        showBadge && (
          <div className={classes.space}>
            <ChatBotAvatar size={20} />
          </div>
        )
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  aiInput: {
    "&.Mui-focused": {
      border: "double 1.5px transparent",
      borderRadius: theme.measure.borderRadius.big,
      boxShadow: `0px 0px 0px 3px ${theme.palette.groovy.purple[200]}`,
      backgroundImage: `linear-gradient(${theme.palette.groovy.neutral[100]}, ${theme.palette.groovy.neutral[100]}), ${theme.palette.aiGradient.aiDark}`,
      backgroundOrigin: "border-box",
      backgroundClip: "padding-box, border-box",
    },
  },

  space: {
    "& svg": {
      width: 20,
      height: 20,
    },
    padding: theme.spacing(0, 1, 0, 0),
    marginBottom: theme.spacing(-2),
  },
}))

export default observer(AIInput, { forwardRef: true })
