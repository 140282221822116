import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import classnames from "classnames"
import { useLayoutEffect } from "react"
import useCountDownTimer from "../../utils/hook/useCountDownTimer"

interface CountdownProps {
  testid: string
  dateInUtc: Date
  timerDelayInSeconds?: number
  // onEnd value should be declared with `useCallback`
  onEnd?: () => void
  className?: string
  disabled?: boolean
}

function Countdown({
  testid,
  dateInUtc,
  timerDelayInSeconds,
  onEnd,
  className,
  disabled,
}: CountdownProps) {
  const countDownData = useCountDownTimer(dateInUtc, {
    disabled,
    cadenceSecs: timerDelayInSeconds,
    showNegative: false,
  })
  const theme = useTheme()
  const isDarkmode = theme.palette.type === "dark"
  let items: { id: "days" | "hours" | "minutes" | "seconds"; count: number }[]

  if (countDownData.days) {
    // No need for minutes when you are 100+ days out
    // it won't fit on smaller screens
    if (countDownData.days > 99) {
      items = [
        { id: "days", count: countDownData.days },
        { id: "hours", count: countDownData.hours },
      ]
    } else {
      items = [
        { id: "days", count: countDownData.days },
        { id: "hours", count: countDownData.hours },
        { id: "minutes", count: countDownData.minutes },
      ]
    }
  } else if (countDownData.hours) {
    items = [
      { id: "hours", count: countDownData.hours },
      { id: "minutes", count: countDownData.minutes },
    ]
  } else {
    items = [
      { id: "minutes", count: countDownData.minutes },
      { id: "seconds", count: countDownData.seconds },
    ]
  }

  useLayoutEffect(() => {
    if (countDownData.delta === 0 && onEnd) {
      onEnd()
    }
  }, [onEnd, countDownData.delta])

  const classes = useStyles({ disabled })

  return (
    <div className={classnames(classes.countdown, className)} data-testid={testid}>
      {items.map((item) => {
        return (
          <div key={item.id} className={classes.countdownBox}>
            <DiscoText
              data-testid={`${item.id}.count`}
              variant={"heading-md"}
              color={isDarkmode ? "groovy.onDark.300" : "groovy.grey.500"}
            >
              {item.count}
            </DiscoText>
            <DiscoText
              variant={"body-xs-500-uppercase"}
              color={isDarkmode ? "groovy.onDark.200" : "groovy.grey.400"}
              marginTop={1}
              className={classes.title}
            >
              {item.id}
            </DiscoText>
          </div>
        )
      })}
    </div>
  )
}

type StyleProps = {
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  countdown: ({ disabled }: StyleProps) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
    width: "100%",
    opacity: disabled ? 0.5 : undefined,
  }),
  countdownBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "94px",
    maxWidth: "100%",
    height: "83px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
    outline: `2px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[100]
    }`,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "70px",
    },
  },
  title: {
    textTransform: "capitalize",
  },
}))

export default Countdown
