import classNames from "classnames"

export default function SwiperSlide({
  index,
  children,
  className,
}: React.PropsWithChildren<{ index?: number; className?: string }>) {
  return (
    <swiper-slide class={classNames(`index-${index}`, className)}>
      {children}
    </swiper-slide>
  )
}
