import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import classNames from "classnames"
import { useState } from "react"
import NewDirectMessageModal from "./NewDirectMessageModal"

interface NewDirectMessageButtonProps {
  className?: string
  children: OverridableDiscoButtonChildren
  stopPropagation?: boolean
}

function NewDirectMessageButton({
  className,
  children,
  stopPropagation = true,
}: NewDirectMessageButtonProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <OverridableDiscoButton
        className={classNames("new-conversation-button", className)}
        onClick={openModal}
      >
        {children}
      </OverridableDiscoButton>

      {/* Re-mount modal each time to reset state on each opening. */}
      {isOpen && <NewDirectMessageModal isOpen={true} onClose={closeModal} />}
    </>
  )

  function openModal(e: React.MouseEvent) {
    if (stopPropagation) e.stopPropagation()
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
}

export default NewDirectMessageButton
