/**
 * @generated SignedSource<<37906f2b9af644c6cc9949896bf32c52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatChannelVisibility = "public" | "private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatChannelBadgeFragment$data = {
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  };
  readonly chatChannel: {
    readonly visibility: ChatChannelVisibility;
  } | null;
  readonly " $fragmentType": "ChatChannelBadgeFragment";
};
export type ChatChannelBadgeFragment$key = {
  readonly " $data"?: ChatChannelBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelBadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelBadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "843b50fa5eefccb832a77d4d732701ba";

export default node;
