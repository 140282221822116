import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextButton, DiscoTextButtonProps } from "@disco-ui"

type AITextButtonProps = DiscoTextButtonProps & {
  className?: string
}

function AITextButton(props: AITextButtonProps) {
  const { children, className, ...rest } = props
  const classes = useStyles()
  return (
    <DiscoTextButton
      {...rest}
      className={className}
      classes={{
        root: classes.root,
      }}
      leftIconClassname={classes.icon}
      rightIconClassname={classes.icon}
    >
      {children}
    </DiscoTextButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    backgroundImage:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurple02
        : theme.palette.aiGradient.bluePurple03,
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  icon: {
    "& svg": {
      color: theme.palette.groovy.blue[300],
    },
  },
}))

export default AITextButton
