import useDebounce from "@utils/hook/useDebounce"
import { useEffect, useRef, useState } from "react"
import { Channel } from "stream-chat"
import { useChatContext } from "stream-chat-react"

function useStreamChannelsTotalUnreadCount(
  loadedChannels: Channel[] | undefined
): number {
  const { client: stream } = useChatContext()

  // Map channels by ID once so we can lookup faster on each event
  const channelsById = (loadedChannels || []).reduce((map, channel) => {
    if (channel.id) map[channel.id] = channel
    return map
  }, {} as Record<string, Channel>)

  const hasSetInitialUnreadCount = useRef(false)

  // a very manual way to track unread count
  const [numUnreads, setNumUnread] = useState(
    (loadedChannels || []).reduce((acc, channel) => acc + channel.state.unreadCount, 0)
  )

  // temp debounce because: if a channel is active (open), the new message will be added to count then immediately dismissed
  // in future we will use stream to detect if a channel is active
  const debouncedSetNumUnread = useDebounce((totalUnreadCount: number) => {
    setNumUnread(totalUnreadCount)
  }, 500)

  useEffect(() => {
    if (!stream) return

    // set initial unread count on load
    if (loadedChannels?.length && !hasSetInitialUnreadCount.current) {
      hasSetInitialUnreadCount.current = true
      debouncedSetNumUnread(
        (loadedChannels || []).reduce(
          (acc, channel) => acc + channel.state.unreadCount,
          0
        )
      )
    }
  }, [loadedChannels, debouncedSetNumUnread, stream])

  useEffect(() => {
    if (!stream) return

    const { unsubscribe } = stream.on((event) => {
      // if it's not a channel we're listening to, bail out
      if (event.channel_id && !channelsById[event.channel_id]) return
      const newUnreadCount = (loadedChannels || []).reduce(
        (acc, channel) => acc + channel.state.unreadCount,
        0
      )
      // total_unread_count includes ANY channels that user is a member of (including other channels in the organization's products, not just DM)
      if (event.total_unread_count && event.total_unread_count > 0) {
        debouncedSetNumUnread(newUnreadCount)
      }
      if (event.type === "notification.mark_read" || event.type === "message.read") {
        debouncedSetNumUnread(newUnreadCount)
      }
    })
    return unsubscribe
  }, [stream, debouncedSetNumUnread, loadedChannels, channelsById])
  return numUnreads
}

export default useStreamChannelsTotalUnreadCount
