import useChatBotDrawer from "@/admin/integrations/chat-bot/useChatBotDrawer"
import useChatBot from "@/admin/integrations/chat-bot/util/useChatBot"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { AIChatProvider } from "@/core/context/AIChatContext"
import { useStreamChannel } from "@/core/context/StreamChatContext"
import Relay from "@/relay/relayUtils"
import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { OpenAIBotDrawerButtonQuery } from "@components/ai-bot/__generated__/OpenAIBotDrawerButtonQuery.graphql"
import NotificationBadge from "@components/square-count-badge/NotificationBadge"
import { DiscoIcon, DiscoTooltip } from "@disco-ui"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { graphql, useLazyLoadQuery } from "react-relay"

function OpenAIBotDrawerButton() {
  const chatBotDrawer = useChatBotDrawer()
  const classes = useStyles()
  const chatBot = useChatBot()
  const activeOrganization = useActiveOrganization()!
  const { node } = useLazyLoadQuery<OpenAIBotDrawerButtonQuery>(
    graphql`
      query OpenAIBotDrawerButtonQuery($id: ID!) {
        node(id: $id) {
          __typename
          ... on Organization {
            chatChannels(kinds: [chat_bot]) {
              edges {
                node {
                  id
                  isDefaultSuggestion
                  externalChannelId
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  const organization = Relay.narrowNodeType(node, "Organization")
  const suggestionsChannels = Relay.connectionToArray(organization?.chatChannels).filter(
    (c) => c.isDefaultSuggestion
  )
  const suggestionsChannel = suggestionsChannels[0]
  const suggestionsStreamChannel = useStreamChannel(suggestionsChannel?.externalChannelId)
  const subscriptionStanding = useSubscriptionStanding()
  const hasPlatformAccess = subscriptionStanding === "active"

  if (!organization || !chatBot) return null
  return (
    <AIChatProvider>
      <DiscoTooltip
        content={chatBot.name}
        disabled={hasPlatformAccess}
        placement={"right"}
      >
        <div className={classes.buttonContainer}>
          <DiscoIconButton
            className={classes.iconButton}
            onClick={handleOpenDrawer}
            disabled={!hasPlatformAccess}
          >
            {chatBotDrawer.isOpen ? (
              <DiscoIcon className={classes.closeIcon} icon={"close"} />
            ) : (
              <AIStarsIcon />
            )}

            {/** Show the suggestions channel notification when the bot drawer is not open */}
            {!chatBotDrawer.isOpen && (
              <NotificationBadge
                className={classes.notificationBadge}
                config={{
                  streamChannelIds: suggestionsChannels.map((c) => c.externalChannelId),
                }}
              />
            )}
          </DiscoIconButton>
        </div>
      </DiscoTooltip>
    </AIChatProvider>
  )

  function handleOpenDrawer() {
    if (suggestionsStreamChannel && suggestionsStreamChannel.countUnread() > 0) {
      chatBotDrawer.open(suggestionsChannel.id) // If suggestions channel has unread messages, auto open to it in the drawer
    } else {
      chatBotDrawer.open()
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  buttonContainer: {
    position: "relative",
  },
  notificationBadge: {
    position: "absolute",
    top: -2,
    right: -2,
    border: `2px solid ${theme.palette.background.default}`,
  },
  closeIcon: {
    color: theme.palette.aiGradient.aiDark,
  },
  iconButton: {
    width: 40,
    height: 40,
    padding: 0,
    backgroundImage: `${theme.palette.aiGradient.aiLight}, ${theme.palette.aiGradient.aiDark}`,
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    border: "double 1px transparent",
    borderRadius: 9999,
    "& svg": {
      width: 24,
      height: 24,
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}))

export default OpenAIBotDrawerButton
