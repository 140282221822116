import { CommandResponse } from "stream-chat"
import {
  CommandItem,
  CommandTriggerSetting,
  useChannelStateContext,
} from "stream-chat-react"

export const useCommandTrigger = (): CommandTriggerSetting => {
  const { channelConfig } = useChannelStateContext()

  const commands = channelConfig?.commands
  // Only allow the "/giphy" command  until we have a better understanding of what other comands do
  const filteredCommands = commands?.filter((command) => command.name === "giphy")

  return {
    component: CommandItem,
    dataProvider: (query, text, onReady) => {
      if (text.indexOf("/") !== 0 || !filteredCommands) {
        return []
      }
      const selectedCommands = filteredCommands.filter(
        (command) => command.name?.indexOf(query) !== -1
      )

      // sort alphabetically unless the you're matching the first char
      selectedCommands.sort((a, b) => {
        let nameA = a.name?.toLowerCase()
        let nameB = b.name?.toLowerCase()
        if (nameA?.indexOf(query) === 0) {
          nameA = `0${nameA}`
        }
        if (nameB?.indexOf(query) === 0) {
          nameB = `0${nameB}`
        }
        // Should confirm possible null / undefined when TS is fully implemented
        if (nameA != null && nameB != null) {
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
        }

        return 0
      })

      const result = selectedCommands.slice(0, 10)
      if (onReady)
        onReady(
          result.filter(
            (r): r is CommandResponse & { name: string } => r.name !== undefined
          ),
          query
        )

      return result
    },
    output: (entity) => ({
      caretPosition: "next",
      key: entity.name,
      text: `/${entity.name}`,
    }),
  }
}
