import ChatChannelAnchorMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelAnchorMarkdown"
import { useMemo } from "react"
import { TranslationLanguages } from "stream-chat"
import { renderText as defaultRenderText, StreamMessage } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

export default function useRenderChatChannelMessage(
  message: StreamMessage<DefaultStreamChatGenerics>,
  userLanguage?: TranslationLanguages,
  renderText = defaultRenderText
) {
  const messageTextToRender =
    message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] ||
    message.text

  return useMemo(
    () => {
      if (!messageTextToRender) return null

      // TODO: this is a temporary fix for the markdown parser not abiding the \n character
      // for 1 new line, replace with 2 new lines and a double space, for 2 new lines,
      // replace with 2 new lines and a empty character `U+200E` padded by 2 double spaces
      // Can remove once https://github.com/GetStream/stream-chat-react/issues/1756 is addressed
      let messageFormatted = messageTextToRender
      if (!messageFormatted.includes("‎")) {
        messageFormatted = messageFormatted
          .replaceAll("\n\n", "\n  ‎  \n")
          .replaceAll("\n", "\n  \n")
      }

      return renderText(messageFormatted, message.mentioned_users, {
        customMarkDownRenderers: {
          link: ChatChannelAnchorMarkdown,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message.mentioned_users, messageTextToRender]
  )
}
