import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"

const ChatChannelThreadStart = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <DiscoText variant={"body-xs-600"}>{"Replies"}</DiscoText>

      <DiscoDivider
        color={theme.palette.groovy.neutral[100]}
        thickness={2}
        className={classes.divider}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    padding: theme.spacing(1.5),
  },
  divider: {
    width: "100%",
  },
}))

export default ChatChannelThreadStart
