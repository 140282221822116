/**
 * @generated SignedSource<<eb3bdb8d75d79fa9b6c4ac22477e475c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type chatUtils_OrganizationMembershipStreamConnectionQuery$variables = {
  id: string;
};
export type chatUtils_OrganizationMembershipStreamConnectionQuery$data = {
  readonly organizationMembership: {
    readonly streamChatUserId?: string | null;
  } | null;
};
export type chatUtils_OrganizationMembershipStreamConnectionQuery = {
  variables: chatUtils_OrganizationMembershipStreamConnectionQuery$variables;
  response: chatUtils_OrganizationMembershipStreamConnectionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streamChatUserId",
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chatUtils_OrganizationMembershipStreamConnectionQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chatUtils_OrganizationMembershipStreamConnectionQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7271aab0c01975c8dcf1cfce20568808",
    "id": null,
    "metadata": {},
    "name": "chatUtils_OrganizationMembershipStreamConnectionQuery",
    "operationKind": "query",
    "text": "query chatUtils_OrganizationMembershipStreamConnectionQuery(\n  $id: ID!\n) {\n  organizationMembership: node(id: $id) {\n    __typename\n    ... on OrganizationMembership {\n      streamChatUserId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac63ec2326663db156e1c3de90f771bf";

export default node;
