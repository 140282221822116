import { CommunitySwitcherListConstants } from "@/community/switcher/CommunitySwitcherList"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { MouseEventHandler } from "react"

interface CommunitySwitcherListItemTemplateProps extends TestIDProps {
  isActive: boolean
  badge: React.ReactNode
  title: React.ReactNode
  subtitle?: string
  onClick: MouseEventHandler
}

export default function CommunitySwitcherListItemTemplate({
  testid = "CommunitySwitcherListItemTemplate",
  isActive,
  badge,
  title,
  subtitle,
  onClick,
}: CommunitySwitcherListItemTemplateProps) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <DiscoContainerButton
      testid={testid}
      className={classNames(classes.item, {
        [classes.active]: isActive,
      })}
      tabIndex={0}
      onClick={onClick}
    >
      {badge}

      <div className={classes.communityDetails}>
        {typeof title === "string" ? (
          <DiscoText
            testid={`${testid}.name`}
            variant={"body-sm-700"}
            setColor={theme.palette.text.primary}
            truncateText={1}
          >
            {title}
          </DiscoText>
        ) : (
          title
        )}
        {subtitle && (
          <DiscoText
            testid={`${testid}.domain`}
            className={classes.domain}
            variant={"body-xs-500"}
            setColor={theme.palette.text.hint}
            truncateText={1}
          >
            {subtitle}
          </DiscoText>
        )}
      </div>
    </DiscoContainerButton>
  )
}

export const CommunitySwitcherListItemTemplateSkeleton = () => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <Skeleton
        height={48}
        width={48}
        variant={"rect"}
        style={{
          borderRadius: theme.measure.borderRadius.big,
        }}
      />
      <div>
        <DiscoTextSkeleton width={140} />
        <DiscoTextSkeleton width={120} />
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "start",
    columnGap: theme.spacing(1.5),
    padding: theme.spacing(0.5),

    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.neutral[500]
          : theme.palette.groovy.neutral[200],
    },

    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: theme.zIndex.raise1,

      backgroundColor: theme.palette.primary.main,
      borderTopRightRadius: theme.measure.borderRadius.default,
      borderBottomRightRadius: theme.measure.borderRadius.default,
      transition: "all 1000ms ease-in-out",
      left: `-${theme.spacing(CommunitySwitcherListConstants.HORIZONTAL_PADDING)}px`,
    },
    "&:hover:not($active)": {
      "&::before": {
        top: "calc(50% - 4px)", // 50% - half height of element
        width: 4,
        height: 8,
      },
    },
  },
  active: {
    "&::before": {
      top: "calc(50% - 12px)", // 50% - half height of element
      width: 4,
      height: 24,
    },
  },
  domain: {
    textDecoration: "underline",
  },
  communityDetails: {
    display: "grid",
    alignItems: "center",
  },
}))
