import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelClickedUserPopover from "@components/chat/channel/clicked-user/ChatChannelClickedUserPopover"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { UserResponse } from "stream-chat"
import { useChannelStateContext } from "stream-chat-react"

interface ChatChannelUserMarkdownProps extends TestIDProps {
  name: string
  streamUserId: string
}

function ChatChannelUserMarkdown({ name, streamUserId }: ChatChannelUserMarkdownProps) {
  const { members } = useChannelStateContext()

  const classes = useStyles()

  const streamUser = members?.[streamUserId]?.user

  const [clickedUser, setClickedUser] = useState<UserResponse | undefined>()
  const [clickedUserTarget, setClickedUserTarget] = useState<HTMLButtonElement>()

  if (!streamUser)
    return (
      <DiscoText variant={"body-sm"} component={"span"}>
        {name}
      </DiscoText>
    )

  return (
    <>
      <DiscoText
        className={classes.link}
        onClick={handleSelect}
        variant={"body-sm-700"}
        color={"groovy.blue.400"}
        component={"span"}
      >{`@${name}`}</DiscoText>
      {clickedUser && clickedUserTarget && (
        <ChatChannelClickedUserPopover
          clickedUser={clickedUser}
          setClickedUser={setClickedUser}
          clickedUserTarget={clickedUserTarget}
        />
      )}
    </>
  )

  function handleSelect(event: React.BaseSyntheticEvent) {
    event.stopPropagation()
    setClickedUser(streamUser)
    setClickedUserTarget(event.target)
  }
}

const useStyles = makeUseStyles({
  link: {
    cursor: "pointer",
  },
})

export default ChatChannelUserMarkdown
