import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { DiscoTooltip } from "@disco-ui"
import ButtonBase from "@material-ui/core/ButtonBase"
import classNames from "classnames"
import React from "react"

interface AuthenticatedUserDropdownButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  classes?: { avatarContainer?: string }
}

function AuthenticatedUserDropdownButton({
  onClick,
  classes: propsClasses,
  ...props
}: AuthenticatedUserDropdownButtonProps) {
  const { authUser } = useAuthUser()

  const classes = useStyles()
  return (
    <DiscoTooltip content={"Profile"} placement={"right"}>
      <ButtonBase
        className={classNames(classes.userButton, "fs-mask")}
        onClick={onClick}
        {...props}
      >
        <div
          className={classNames(classes.avatarContainer, propsClasses?.avatarContainer)}
        >
          <ProfileAvatar
            userKey={authUser!}
            size={40}
            testid={"AuthenticatedUserDropdown.UserAvatar"}
            placeholderClassName={classes.avatar}
          />
        </div>
      </ButtonBase>
    </DiscoTooltip>
  )
}

const useStyles = makeUseStyles({
  avatar: {
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  userButton: {
    position: "relative",
    textAlign: "left",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "40px",
    height: "40px",
  },
})

export default AuthenticatedUserDropdownButton
