import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ContentBox from "@components/content-box/ContentBox"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { FormatDateUtilOptions } from "@utils/time/timeTypes"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import classnames from "classnames"
import React from "react"

interface DaySquareProps {
  testid: string
  date: Date
  customClassName?: string
  customSquareMonthClassName?: string
  customSquareDayClassName?: string
  children?: React.ReactNode
  formatterOptions?: FormatDateUtilOptions
}

function DaySquare(props: DaySquareProps) {
  const {
    testid,
    date,
    customClassName,
    customSquareMonthClassName,
    customSquareDayClassName,
    children,
  } = props
  const timeZone = useUserTimezone()
  const formatterOptions: FormatDateUtilOptions = {
    timeZone,
    ...props.formatterOptions,
  }
  const classes = useStyles()

  return (
    <ContentBox customClassName={classnames(classes.container, customClassName)}>
      <DiscoText
        variant={"body-xs-500-uppercase"}
        component={"p"}
        color={"groovy.red.400"}
        lineHeight={12}
        className={customSquareMonthClassName}
        data-testid={`${testid}.month`}
      >
        {formatDateWithOptions({
          ...formatterOptions,
          format: DATE_FORMAT.MONTH,
        })(date)}
      </DiscoText>

      <DiscoText
        variant={"body-lg-700"}
        component={"p"}
        data-testid={`${testid}.day`}
        lineHeight={18}
        marginTop={0.25}
        className={customSquareDayClassName}
      >
        {formatDateWithOptions({
          ...formatterOptions,
          format: DATE_FORMAT.DAY_LONG,
        })(date)}
      </DiscoText>
      {children}
    </ContentBox>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    width: "48px",
    height: "48px",

    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export function DaySquareSkeleton(props: Pick<DaySquareProps, "customClassName">) {
  const theme = useTheme()
  return (
    <Skeleton
      variant={"rect"}
      style={{ borderRadius: theme.measure.borderRadius.medium }}
      width={48}
      height={48}
      className={props.customClassName}
    />
  )
}

export default DaySquare
