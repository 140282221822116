import ChatBotSparkles from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIText from "@components/ai/AIText"
import DiscoBanner, { DiscoBannerProps } from "@disco-ui/banner/DiscoBanner"

type AIBannerProps = Omit<DiscoBannerProps, "variant">

function AIBanner({ testid, message, buttons, ...rest }: AIBannerProps) {
  const classes = useStyles()

  return (
    <DiscoBanner
      testid={testid}
      variant={"copilot"}
      classes={{ banner: classes.banner, icon: classes.icon }}
      icon={<ChatBotSparkles width={24} height={24} />}
      message={<AIText variant={"heading-xs-700"}>{message}</AIText>}
      buttons={buttons}
      {...rest}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  banner: {
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: `${
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight
    } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
    border: "1px solid transparent",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
}))

export default AIBanner
