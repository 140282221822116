import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import RecurringEventConfirmAttendanceModal from "@components/buttons/RecurringEventConfirmAttendanceModal"
import { RSVPToEventButtonFragment$key } from "@components/buttons/__generated__/RSVPToEventButtonFragment.graphql"
import { RSVPToEventButtonModalPreloadedQuery } from "@components/buttons/__generated__/RSVPToEventButtonModalPreloadedQuery.graphql"
import { RSVPToEventButtonMutation } from "@components/buttons/__generated__/RSVPToEventButtonMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonProps,
  DiscoIcon,
  DiscoIconButton,
  DiscoTooltip,
} from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { graphql, useFragment, useQueryLoader } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate } from "relay-runtime"

interface Props extends TestIDProps, Pick<DiscoButtonProps, "color" | "size" | "width"> {
  occurrenceKey: RSVPToEventButtonFragment$key
  text?: string
  className?: string
  hideIcon?: boolean
  iconOnly?: boolean
  enableAutoRSVP?: boolean
  enableRecurringEventConfirmModal?: boolean
}

export const RSVPToEventButtonPreloadedQuery = graphql`
  query RSVPToEventButtonModalPreloadedQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Occurrence {
        ...RecurringEventConfirmAttendanceModalFragment @relay(mask: false)
      }
    }
  }
`

export type OneClickRSVPQueryParams = {
  rsvp?: GlobalID
  rsvpToEventId?: GlobalID
}

function RSVPToEventButton(props: Props) {
  const {
    occurrenceKey,
    testid = "RSVPToEventButton",
    text,
    className,
    hideIcon,
    iconOnly,
    enableAutoRSVP = false,
    enableRecurringEventConfirmModal = false,
    ...buttonProps
  } = props

  const activeOrganization = useActiveOrganization()!
  const { isOpen, onClose, onOpen } = useDisclosure()
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const location = useLocation()
  const history = useHistory()

  const occurrence = useFragment<RSVPToEventButtonFragment$key>(
    graphql`
      fragment RSVPToEventButtonFragment on Occurrence {
        id
        status
        hasViewerRSVPd
        capacity
        atCapacity
        event {
          id
          isRecurring
        }
      }
    `,
    occurrenceKey
  )

  const isDraft = occurrence.status === "draft"

  const [queryReference, loadQuery] =
    useQueryLoader<RSVPToEventButtonModalPreloadedQuery>(RSVPToEventButtonPreloadedQuery)

  const form = useFormStore<RSVPToEventButtonMutation>(
    graphql`
      mutation RSVPToEventButtonMutation($input: RSVPToEventInput!) {
        response: rsvpToEvent(input: $input) {
          occurrences {
            edges {
              node {
                hasViewerRSVPd
                atCapacity
                rsvps {
                  totalCount
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {}
  )

  // !Deprecated: See reason inside useEffect
  const { rsvp: oneClickRsvpID, rsvpToEventId: oneClickRsvpToSeriesID } =
    useQueryParams<OneClickRSVPQueryParams>()

  useEffect(() => {
    if (!enableAutoRSVP) return

    // Backwards compatibility (we used to auto rsvp straight from this button but now we go to another page and then redirect back)
    if (oneClickRsvpID || oneClickRsvpToSeriesID) {
      history.replace({
        pathname: ROUTE_NAMES.AUTO_RSVP,
        search: `id=${
          oneClickRsvpID || oneClickRsvpToSeriesID
        }&redirectUrl=${encodeURIComponent(
          location.pathname + location.search.split("&rsvp")[0].split("&rsvpToEventId")[0]
        )}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneClickRsvpID,
    oneClickRsvpToSeriesID,
    occurrence.id,
    occurrence.event.id,
    enableAutoRSVP,
  ])

  const buttonText = getButtonText()
  const isDisabled =
    isDraft || occurrence.hasViewerRSVPd || form.isSubmitting || occurrence.atCapacity

  return (
    <>
      <DiscoTooltip
        disabled={!isDraft}
        content={"Publish event before confirming attendance"}
      >
        {renderButton()}
      </DiscoTooltip>
      {/** Only render the modal when the query is loaded */}
      {isOpen && queryReference && (
        <RecurringEventConfirmAttendanceModal
          testid={testid}
          isOpen={isOpen}
          onClose={onClose}
          occurrenceQueryRef={queryReference}
        />
      )}
    </>
  )

  function renderButton() {
    if (iconOnly)
      return (
        <DiscoIconButton
          testid={`${testid}.add-event-to-calendar`}
          color={"grey"}
          variant={"outlined"}
          onClick={handleButtonClick}
          disabled={isDisabled}
          className={className}
          shouldDisplaySpinner={form.isSubmitting}
        >
          {renderIcon()}
        </DiscoIconButton>
      )

    return (
      <DiscoButton
        testid={`${testid}.add-event-to-calendar`}
        leftIcon={renderIcon()}
        color={"grey"}
        variant={"outlined"}
        onClick={handleButtonClick}
        disabled={isDisabled}
        className={className}
        shouldDisplaySpinner={form.isSubmitting}
        {...buttonProps}
      >
        {buttonText}
      </DiscoButton>
    )
  }

  function renderIcon() {
    if (hideIcon) return undefined
    if (occurrence.hasViewerRSVPd) return <DiscoIcon icon={"iconsax.custom-check"} />
    return <CalendarIcon />
  }

  function getButtonText() {
    if (occurrence.atCapacity) {
      return "At Capacity"
    }

    if (occurrence.hasViewerRSVPd) {
      return mdDown ? "Sent" : "Email Sent!"
    }

    if (text) return text
    return "Attend"
  }

  async function handleButtonClick(e: React.SyntheticEvent<HTMLButtonElement>) {
    // needed for OrganizationOccurrenceListItem
    e.preventDefault()
    e.stopPropagation()

    if (occurrence.event.isRecurring && enableRecurringEventConfirmModal) {
      loadPreloadedQuery()
      onOpen()
      return
    }

    const { didSave } = await form.submit({ occurrenceId: occurrence.id })

    if (!didSave) return

    commitLocalUpdate(RelayEnvironment, (store) => {
      const org = store.get(activeOrganization.id)
      if (!org) return

      ConnectionHandler.getConnections(
        org,
        "RSVPToAllOrganizationEventsButton_occurrences"
      ).forEach((connection) => connection.invalidateRecord())
    })

    displaySuccessToast({
      message: "You confirmed attendance. Check your email for more details!",
      testid: `RSVPToEventButton.success-toast`,
    })
  }

  // Load the preloaded query if the queryReference doesn't already exist
  function loadPreloadedQuery() {
    if (queryReference) return
    loadQuery({ id: occurrence.id })
  }
}

export default observer(RSVPToEventButton)
