import ExternalIcon from "@/core/ui/iconsax/linear/export-2.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoTextButton, DiscoTextButtonProps } from "@disco-ui"

export type ExternalDiscoTextButtonProps = {
  title: string | React.ReactNode
  iconPosition?: "left" | "right"
} & StyleProps &
  Omit<DiscoTextButtonProps, "variant">

const ExternalDiscoTextButton: React.FC<ExternalDiscoTextButtonProps> = ({
  to,
  color,
  children,
  title,
  disabledColor,
  iconPosition = "left",
  ...props
}) => {
  const classes = useStyles({ color, disabledColor })

  return (
    <DiscoTextButton
      color={color}
      leftIcon={
        iconPosition === "left" ? (
          <ExternalIcon className={classes.externalIcon} />
        ) : undefined
      }
      rightIcon={
        iconPosition === "right" ? (
          <ExternalIcon className={classes.externalIcon} />
        ) : undefined
      }
      classes={{ disabled: classes.disabled }}
      to={to}
      target={"_blank"}
      linkClassname={classes.title}
      {...props}
    >
      {title}
      {children}
    </DiscoTextButton>
  )
}

type StyleProps = {
  color?: React.CSSProperties["color"]
  disabledColor?: React.CSSProperties["color"]
}

const useStyles = makeUseStyles((theme) => ({
  title: ({ color }: StyleProps) => ({
    "&, &:visited": {
      color: color || theme.palette.primary.main,
    },
  }),
  externalIcon: ({ color }: StyleProps) => ({
    marginTop: theme.spacing(0.25),
    "& path": {
      color: color || theme.palette.primary.main,
    },
    "& > path:nth-child(2)": {
      color: color || theme.palette.primary.main,
    },
  }),
  disabled: ({ disabledColor }: StyleProps) => ({
    "& .MuiButton-label > a": {
      color: disabledColor || theme.palette.groovy.neutral[300],
    },
    "& svg": {
      "& path": {
        color: disabledColor || theme.palette.groovy.neutral[300],
      },
      "& > path:nth-child(2)": {
        color: disabledColor || theme.palette.groovy.neutral[300],
      },
    },
  }),
}))

export default ExternalDiscoTextButton
