import makeUseStyles from "@assets/style/util/makeUseStyles"
import useIsMobileAgent from "@utils/hook/useIsMobileAgent"
import classnames from "classnames"
import React, { Fragment, useState } from "react"
import {
  BeforeCapture,
  DragDropContext,
  DragDropContextProps,
  DragStart,
  DragUpdate,
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd"

export interface DragDropProps {
  uniqueKey: string
  items: { id: string }[]
  customClassName?: string
  classes?: {
    list?: string
    draggable?: string
  }
  isDragDisabled?: boolean
  onDragEnd: DragDropContextProps["onDragEnd"]
  showDropPlaceholder?: boolean
  children: (
    item: any,
    testid: string,
    index: number,
    isDragging: boolean,
    dragHandleProps?: DraggableProvidedDragHandleProps
  ) => JSX.Element
  forwardDragHandle?: boolean
  onBeforeCapture?: (before: BeforeCapture) => void
}

function DragDrop({
  items,
  uniqueKey,
  children,
  onDragEnd,
  customClassName,
  isDragDisabled,
  showDropPlaceholder,
  forwardDragHandle = false,
  onBeforeCapture,
  ...props
}: DragDropProps) {
  const [placeholderProps, setPlaceholderProps] = useState<{
    clientHeight: number
    clientWidth: number
    clientY: number
  } | null>(null)
  const classes = useStyles({ placeholderProps })
  const isMobileAgent = useIsMobileAgent()

  return (
    <div className={classnames(classes.container, customClassName)}>
      <DragDropContext
        onDragEnd={(...dragDropContextProps) => {
          onDragEnd(...dragDropContextProps)

          // Reset placeholder props
          if (placeholderProps) {
            setPlaceholderProps(null)
          }
        }}
        onDragUpdate={handleDragUpdate}
        onDragStart={handleDragStart}
        onBeforeCapture={onBeforeCapture}
      >
        <Droppable
          droppableId={`drag-drop__droppable--${uniqueKey}`}
          // Setting renderClone causes RBD to portal the dragging item to the document
          // body, which fixes position when used inside elements with transform styles.
          // We can't do this on mobile though, due to a bug that breaks drag behaviour:
          // https://github.com/atlassian/react-beautiful-dnd/issues/1869
          renderClone={isMobileAgent ? undefined : renderItem}
        >
          {(droppableProvided) => (
            <ul
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className={props.classes?.list}
            >
              {items.map((item, index) => {
                const draggableId = `${item.id}-${uniqueKey}`
                return (
                  <Draggable
                    key={draggableId}
                    isDragDisabled={isDragDisabled}
                    draggableId={draggableId}
                    index={index}
                  >
                    {renderItem}
                  </Draggable>
                )
              })}

              {droppableProvided.placeholder}
              {placeholderProps && showDropPlaceholder && (
                <div className={classes.placeholder} />
              )}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )

  function handleDragStart(result: DragStart) {
    const { source, draggableId } = result
    updatePlaceholderPosition(draggableId, source.droppableId, source.index)
  }

  function handleDragUpdate(result: DragUpdate) {
    const { source, draggableId, destination } = result
    updatePlaceholderPosition(
      draggableId,
      source.droppableId,
      destination?.index ?? source.index
    )
  }

  function updatePlaceholderPosition(
    draggableId: string,
    droppableId: string,
    currentIndex: number
  ) {
    if (!showDropPlaceholder) return

    // This attribute gets added to each draggable item which contains the draggableId
    const draggedEl = document.querySelector(
      `[data-rbd-drag-handle-draggable-id='${draggableId}']`
    )
    if (!draggedEl) return
    const { clientHeight, clientWidth } = draggedEl

    // The dragged element is portaled to <body>, so we need to use the droppableId
    // to get the original parent element & siblings
    const droppableEl = document.querySelector(`[data-rbd-droppable-id='${droppableId}']`)
    if (!droppableEl) return

    /**
     * clientY is used to determine where we should render the placeholder element
     * To calculate this position we need to:
     * 1. Take all the items in the list as an array
     * 2. Slice from the start to where we are 'picking up' the item we want to drag
     * 3. Reduce and fetch the styles of each item
     * 4. Add up the margins, heights, paddings
     * 5. Accumulate and assign that to clientY
     */
    const previousSiblings = [...droppableEl.children].slice(0, currentIndex)
    const clientY =
      parseFloat(window.getComputedStyle(droppableEl).paddingTop) +
      previousSiblings.reduce((total, curr) => {
        const style = window.getComputedStyle(curr)
        const marginTop = parseFloat(style.marginTop)
        const marginBottom = parseFloat(style.marginBottom)
        return total + curr.clientHeight + marginTop + marginBottom
      }, 0)

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
    })
  }

  function renderItem(
    draggableProvided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) {
    const item = items[rubric.source.index]
    return (
      <div
        {...draggableProvided.draggableProps}
        {...(forwardDragHandle ? undefined : draggableProvided.dragHandleProps)}
        ref={draggableProvided.innerRef}
        className={props?.classes?.draggable}
      >
        <Fragment key={item.id}>
          <MemoizedChildren
            draggableProvided={draggableProvided}
            snapshot={snapshot}
            rubric={rubric}
            item={item}
            forwardDragHandle={forwardDragHandle}
          >
            {children}
          </MemoizedChildren>
        </Fragment>
      </div>
    )
  }
}

type StyleProps = {
  placeholderProps: {
    clientHeight: number
    clientWidth: number
    clientY: number
  } | null
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
  },
  placeholder: ({ placeholderProps }: StyleProps) => ({
    position: placeholderProps ? "absolute" : undefined,
    top: placeholderProps?.clientY,
    height: placeholderProps?.clientHeight,
    width: placeholderProps?.clientWidth,
    opacity: 0.6,
    borderRadius: theme.measure.borderRadius.default,
    border: `2px dashed ${theme.palette.groovy.grey[700]}`,
  }),
}))

/**
 * Memoized wrapper for the draggable item children that prevents rerendering while the
 * item is being dragged for improved performance.
 */
type MemoizedChildrenProps = Pick<DragDropProps, "children" | "forwardDragHandle"> & {
  draggableProvided: DraggableProvided
  snapshot: DraggableStateSnapshot
  rubric: DraggableRubric
  item: { id: string }
}
const MemoizedChildren = React.memo(
  (props: MemoizedChildrenProps) => {
    const { draggableProvided, snapshot, rubric, item, children, forwardDragHandle } =
      props
    return children(
      item,
      `${item.id}-${rubric.source.index}`,
      rubric.source.index,
      snapshot.isDragging,
      forwardDragHandle ? draggableProvided.dragHandleProps : undefined
    )
  },
  (prevProps, nextProps) => prevProps.snapshot.isDragging && nextProps.snapshot.isDragging
)

export default DragDrop
