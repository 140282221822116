import MentionUserItem from "@components/chat/channel/trigger/MentionUseritem"
import { useCallback, useState } from "react"
import { UserResponse } from "stream-chat"
import {
  MessageInputProps,
  useChannelStateContext,
  useChatContext,
  UserTriggerSetting,
} from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

export type UserTriggerParams<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  onSelectUser: (item: UserResponse<StreamChatGenerics>) => void
  disableMentions?: boolean
  mentionAllAppUsers?: boolean
  mentionQueryParams?: MessageInputProps<StreamChatGenerics>["mentionQueryParams"]
  useMentionsTransliteration?: boolean
}

export function useUserTrigger(params: UserTriggerParams): UserTriggerSetting {
  const {
    disableMentions,
    mentionAllAppUsers,
    mentionQueryParams = {},
    onSelectUser,
    useMentionsTransliteration,
  } = params

  const [searching, setSearching] = useState(false)

  const { client, mutes } = useChatContext()
  const { channel } = useChannelStateContext()

  const { members } = channel.state
  const { watchers } = channel.state

  const getMembersAndWatchers = useCallback(() => {
    const memberUsers = members ? Object.values(members).map(({ user }) => user) : []
    const watcherUsers = watchers ? Object.values(watchers) : []
    const users = [...memberUsers, ...watcherUsers]

    // make sure we don't list users twice
    const uniqueUsers = {} as Record<string, UserResponse>

    users.forEach((user) => {
      if (user && !uniqueUsers[user.id]) {
        uniqueUsers[user.id] = user
      }
    })

    return Object.values(uniqueUsers)
  }, [members, watchers])

  const queryMembersThrottled = useCallback(
    async (query: string, onReady: (users: UserResponse[]) => void) => {
      try {
        const response = await channel.queryMembers({
          name: { $autocomplete: query },
        })

        const users = response.members.map((member) => member.user) as UserResponse[]

        if (onReady && users.length) {
          onReady(users)
        } else {
          onReady([])
        }
      } catch (error) {
        console.log({ error })
      }
    },
    [channel]
  )

  const queryUsers = async (query: string, onReady: (users: UserResponse[]) => void) => {
    if (!query || searching) return
    setSearching(true)

    try {
      const { users } = await client.queryUsers(
        {
          $or: [{ id: { $autocomplete: query } }, { name: { $autocomplete: query } }],
          // @ts-ignore-error
          id: { $ne: client.userID },
          ...mentionQueryParams.filters,
        },
        { id: 1, ...mentionQueryParams.sort },
        { limit: 10, ...mentionQueryParams.options }
      )

      if (onReady && users.length) {
        onReady(users)
      } else {
        onReady([])
      }
    } catch (error) {
      console.log({ error })
    }

    setSearching(false)
  }

  return {
    callback: (item) => onSelectUser(item),
    component: MentionUserItem,
    dataProvider: (query, text, onReady) => {
      if (disableMentions) return

      const filterMutes = (data: UserResponse[]) => {
        if (text.includes("/unmute") && !mutes.length) {
          return []
        }
        if (!mutes.length) return data

        if (text.includes("/unmute")) {
          return data.filter((suggestion) =>
            mutes.some((mute) => mute.target.id === suggestion.id)
          )
        }
        return data.filter((suggestion) =>
          mutes.every((mute) => mute.target.id !== suggestion.id)
        )
      }

      if (mentionAllAppUsers) {
        return queryUsers(query, (data: UserResponse[]) => {
          if (onReady) onReady(filterMutes(data), query)
        })
      }

      /**
       * By default, we return maximum 100 members via queryChannels api call.
       * Thus it is safe to assume, that if number of members in channel.state is < 100,
       * then all the members are already available on client side and we don't need to
       * make any api call to queryMembers endpoint.
       */
      if (!query || Object.values(members || {}).length < 100) {
        const users = getMembersAndWatchers()

        const searchParams: SearchLocalUserParams = {
          ownUserId: client.userID,
          query,
          text,
          useMentionsTransliteration,
          users,
        }

        const matchingUsers = searchLocalUsers(searchParams)

        const usersToShow = mentionQueryParams.options?.limit || 10
        const data = matchingUsers.slice(0, usersToShow)

        if (onReady) onReady(filterMutes(data), query)
        return data
      }

      return queryMembersThrottled(query, (data: UserResponse[]) => {
        if (onReady) onReady(filterMutes(data), query)
      })
    },
    output: (entity) => ({
      caretPosition: "next",
      key: entity.id,
      text: `@${entity.name || entity.id}`,
    }),
  }
}

const accentsMap: { [key: string]: string } = {
  a: "á|à|ã|â|À|Á|Ã|Â",
  c: "ç|Ç",
  e: "é|è|ê|É|È|Ê",
  i: "í|ì|î|Í|Ì|Î",
  n: "ñ|Ñ",
  o: "ó|ò|ô|ő|õ|Ó|Ò|Ô|Õ",
  u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
}

const removeDiacritics = (text?: string) => {
  if (!text) return ""
  return Object.keys(accentsMap).reduce(
    (acc, current) => acc.replace(new RegExp(accentsMap[current], "g"), current),
    text
  )
}

const calculateLevenshtein = (query: string, name: string) => {
  if (query.length === 0) return name.length
  if (name.length === 0) return query.length

  const matrix = []

  let i
  for (i = 0; i <= name.length; i++) {
    matrix[i] = [i]
  }

  let j
  for (j = 0; j <= query.length; j++) {
    matrix[0][j] = j
  }

  for (i = 1; i <= name.length; i++) {
    for (j = 1; j <= query.length; j++) {
      if (name.charAt(i - 1) === query.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        )
      }
    }
  }

  return matrix[name.length][query.length]
}

type SearchLocalUserParams<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  ownUserId: string | undefined
  query: string
  text: string
  users: UserResponse<StreamChatGenerics>[]
  useMentionsTransliteration?: boolean
}

const searchLocalUsers = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  params: SearchLocalUserParams
): UserResponse<StreamChatGenerics>[] => {
  const { ownUserId, query, text, useMentionsTransliteration, users } = params

  const matchingUsers = users.filter((user) => {
    if (user.id === ownUserId) return false
    if (!query) return true

    let updatedId = removeDiacritics(user.id).toLowerCase()
    let updatedName = removeDiacritics(user.name).toLowerCase()
    let updatedQuery = removeDiacritics(query).toLowerCase()

    if (useMentionsTransliteration) {
      ;(async () => {
        const { default: transliterate } = await import("@stream-io/transliterate")
        updatedName = transliterate(user.name || "").toLowerCase()
        updatedQuery = transliterate(query).toLowerCase()
        updatedId = transliterate(user.id).toLowerCase()
      })()
    }

    const maxDistance = 3
    const lastDigits = text.slice(-(maxDistance + 1)).includes("@")

    if (updatedName) {
      const levenshtein = calculateLevenshtein(updatedQuery, updatedName)
      if (
        updatedName.includes(updatedQuery) ||
        (levenshtein <= maxDistance && lastDigits)
      ) {
        return true
      }
    }

    const levenshtein = calculateLevenshtein(updatedQuery, updatedId)

    return updatedId.includes(updatedQuery) || (levenshtein <= maxDistance && lastDigits)
  })

  return matchingUsers
}
