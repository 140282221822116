import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelAttachBlock from "@components/chat/channel/attachment/ChatChannelAttachBlock"
import { VALID_ATTACH_BLOCK_TYPES } from "@components/chat/channel/detail/message/ChatChannelMessage"
import { ChatChannelFilePreviewer } from "@components/chat/channel/uploads/ChatChannelFilePreviewer"
import { ChatChannelImagePreviewer } from "@components/chat/channel/uploads/ChatChannelImagePreviewer"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { useEffect, useState } from "react"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

/** Display a list of uploaded images, files
 * Based off the `stream-chat-react` component
 * ref: https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/UploadsPreview.tsx
 */
export const ChatChannelUploadsPreview = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>() => {
  const { maxNumberOfFiles, multipleUploads } =
    useChannelStateContext<StreamChatGenerics>("ChatChannelUploadsPreview")
  const messageInput = useMessageInputContext<StreamChatGenerics>("UploadsPreview")
  const {
    fileOrder,
    fileUploads,
    imageOrder,
    imageUploads,
    numberOfUploads,
    removeFile,
    removeImage,
    uploadFile,
    uploadImage,
    uploadNewFiles,
  } = messageInput
  const [attachments, setAttachments] = useState(messageInput.attachments)

  useEffect(() => {
    setAttachments(messageInput.attachments)
  }, [attachments, messageInput])

  const classes = useStyles()
  const imagesToPreview = imageOrder
    .map((id) => imageUploads[id])
    .filter((image) => !image.og_scrape_url)
  const filesToPreview = fileOrder.map((id) => fileUploads[id])

  return (
    <div className={classes.previewContainer}>
      <div className={classes.fileContainer}>
        {imageOrder.length > 0 && (
          <div>
            <ChatChannelImagePreviewer
              disabled={
                !multipleUploads ||
                (maxNumberOfFiles !== undefined && numberOfUploads >= maxNumberOfFiles)
              }
              handleFiles={uploadNewFiles}
              handleRemove={removeImage}
              handleRetry={uploadImage}
              imageUploads={imagesToPreview}
              multiple={multipleUploads}
            />
          </div>
        )}
        {fileOrder.length > 0 && (
          <div>
            <ChatChannelFilePreviewer
              handleFiles={uploadNewFiles}
              handleRemove={removeFile}
              handleRetry={uploadFile}
              uploads={filesToPreview}
            />
          </div>
        )}
      </div>
      <div className={classes.attachmentContainer}>
        {attachments
          .filter(
            (attachment) =>
              attachment.type &&
              VALID_ATTACH_BLOCK_TYPES.includes(attachment.type as AttachBlockEntity)
          )
          .map((attachment) =>
            (() => {
              return (
                <ChatChannelAttachBlock
                  type={attachment.type as AttachBlockEntity}
                  entityId={attachment.text!}
                  handleRemove={removeAttachBlock}
                />
              )
            })()
          )}
      </div>
    </div>
  )
  function removeAttachBlock(id: string) {
    const index = attachments.findIndex((attachment) => attachment.text === id)
    if (index !== -1) {
      messageInput.attachments.splice(index, 1)
      const updatedAttachments = [...messageInput.attachments]
      setAttachments(updatedAttachments)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  previewContainer: {
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1.5),

    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    "& .rfu-file-previewer__close-button": {
      display: "none",
    },
  },
  attachmentContainer: {
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}))
