import { useBotThreadSuggestion } from "@/chat/channel/BotSuggestionsContext"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelBotMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelBotMarkdown"
import { ChatChannelBotSuggestedMessageFragment$key } from "@components/chat/channel/detail/message/suggestion/__generated__/ChatChannelBotSuggestedMessageFragment.graphql"
import { ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation } from "@components/chat/channel/detail/message/suggestion/__generated__/ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation.graphql"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"
import { useMessageContext } from "stream-chat-react"

interface Props {
  threadId: string
  inThread?: boolean
  botMessageKey: ChatChannelBotSuggestedMessageFragment$key
}

function ChatChannelBotSuggestedMessage(props: Props) {
  const { threadId, inThread = false, botMessageKey } = props
  const theme = useTheme()
  const classes = useStyles({ inThread })
  const adminLabel = useLabel("organization_admin")
  const { handleOpenThread } = useMessageContext()
  const { selectSuggestion } = useBotThreadSuggestion({ threadId })

  const responseRef = useRef<HTMLDivElement>(null)
  const [isReadyToScroll, setIsReadyToScroll] = useState(false)

  function handleSeeResponse(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpenThread(e)
    setIsReadyToScroll(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      responseRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      setIsReadyToScroll(false)
    }, 100)
    return () => clearTimeout(timer)
  }, [isReadyToScroll, responseRef])

  const botMessage = useFragment<ChatChannelBotSuggestedMessageFragment$key>(
    graphql`
      fragment ChatChannelBotSuggestedMessageFragment on BotQueuedMessage {
        id
        suggestedMessage
      }
    `,
    botMessageKey
  )
  const [commit, isDismissing] =
    useMutation<ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation>(graphql`
      mutation ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation($id: ID!) {
        dismissBotSuggestion(id: $id) {
          node {
            id @deleteRecord
          }
        }
      }
    `)

  if (!botMessage.suggestedMessage) return null

  if (!inThread) {
    return (
      <div className={classes.gradientBorder}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.title}>
              <DiscoIcon icon={"sparkles"} color={theme.palette.groovy.blue[400]} />
              <DiscoText variant={"body-md-600"} color={"groovy.blue.400"}>
                {"Disco AI suggested response:"}
              </DiscoText>
            </div>
            <DiscoButton
              className={classes.button}
              size={"small"}
              onClick={handleSeeResponse}
            >
              {"See Response"}
            </DiscoButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div ref={responseRef} className={classes.gradientBorder}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            <DiscoIcon icon={"sparkles"} color={theme.palette.groovy.blue[400]} />
            <DiscoText variant={"body-md-600"} color={"groovy.blue.400"}>
              {"Disco AI suggested response:"}
            </DiscoText>
          </div>
          <div className={classes.title}>
            <DiscoIcon
              height={18}
              width={18}
              icon={"eye"}
              color={theme.palette.groovy.neutral[500]}
            />
            <DiscoText
              variant={"body-sm-500"}
              color={"groovy.neutral.500"}
            >{`${adminLabel.plural} only`}</DiscoText>
          </div>
        </div>
        <ChatChannelBotMarkdown message={botMessage.suggestedMessage} />
        <div className={classes.buttons}>
          <DiscoTooltip content={`Dismiss this suggestion for all ${adminLabel.plural}`}>
            <DiscoButton
              shouldDisplaySpinner={isDismissing}
              className={classes.button}
              size={"small"}
              variant={"outlined"}
              color={"grey"}
              onClick={dismissSuggestion}
            >
              {"Dismiss"}
            </DiscoButton>
          </DiscoTooltip>
          <DiscoTooltip content={"You will be able to edit the message before sending"}>
            <DiscoButton
              className={classes.button}
              size={"small"}
              onClick={selectSuggestion}
            >
              {"Edit & Use"}
            </DiscoButton>
          </DiscoTooltip>
        </div>
      </div>
    </div>
  )
  function dismissSuggestion() {
    commit({
      variables: { id: botMessage.id },
    })
  }
}
type StyleProps = {
  inThread: boolean
}

const useStyles = makeUseStyles((theme) => ({
  gradientBorder: {
    background: theme.palette.gradient.copilotDark,
    padding: "1px",
    borderRadius: theme.measure.borderRadius.big,
    overflow: "hidden",

    [theme.breakpoints.up("md")]: {
      margin: (props: StyleProps) =>
        props.inThread ? theme.spacing(0, 1) : theme.spacing(0, 1, 0, 6),
    },
  },
  root: {
    display: "grid",
    gap: theme.spacing(1),
    background: theme.palette.gradient.copilot,
    padding: theme.spacing(1.5),
    borderRadius: Number(theme.measure.borderRadius.big) - 1,

    "& a": { fontSize: "14px" },
  },
  header: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  button: {
    "& span": {
      fontSize: "14px",
    },
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
}))

export default ChatChannelBotSuggestedMessage
