import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelPinnedMessagesList from "@components/chat/channel/pinned-messages/ChatChannelPinnedMessagesList"
import { DiscoDrawer, DiscoDrawerHeader, DiscoIcon, DiscoText } from "@disco-ui"

type Props = {
  isOpen: boolean
  onClose: () => void
}

function ChatChannelPinnedMessagesDrawer({ isOpen, onClose }: Props) {
  const classes = useStyles()

  return (
    <DiscoDrawer
      title={"Pinned Messages"}
      open={isOpen}
      onClose={onClose}
      size={"large"}
      containerClasses={{ drawerContainer: classes.container }}
    >
      <div
        data-testid={"ChatChannelPinnedMessagesDrawer.container"}
        className={classes.container}
      >
        <div className={classes.headerContainer}>
          <DiscoDrawerHeader
            testid={"ExperienceSettingsDrawerHeader"}
            disableExpand
            title={
              <div className={classes.titleContainer}>
                <DiscoIcon icon={"pin"} />
                <DiscoText display={"inline"} variant={"body-lg-600"}>
                  {"Pinned Messages"}
                </DiscoText>
              </div>
            }
            onClose={onClose}
            className={classes.header}
          />
        </div>

        <ChatChannelPinnedMessagesList />
      </div>
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: 0,
  },
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.raise2,
    backgroundColor: theme.palette.background.paper,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(1, 2, 0, 2),
  },
}))

export default ChatChannelPinnedMessagesDrawer
