import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import { RSVPToEventButtonPreloadedQuery } from "@components/buttons/RSVPToEventButton"
import { RecurringEventConfirmAttendanceModalMutation } from "@components/buttons/__generated__/RecurringEventConfirmAttendanceModalMutation.graphql"
import { RSVPToEventButtonModalPreloadedQuery } from "@components/buttons/__generated__/RSVPToEventButtonModalPreloadedQuery.graphql"
import { RSVPToEventInput } from "@components/buttons/__generated__/RSVPToEventButtonMutation.graphql"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoAlert, DiscoModal, DiscoModalProps, DiscoRadio, DiscoText } from "@disco-ui"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate } from "relay-runtime"

type ConfirmAttendanceTo = "single" | "all"

interface Props extends Pick<DiscoModalProps, "testid" | "isOpen"> {
  onClose: () => void
  occurrenceQueryRef: PreloadedQuery<RSVPToEventButtonModalPreloadedQuery>
}

function RecurringEventConfirmAttendanceModal({
  testid,
  isOpen,
  onClose,
  occurrenceQueryRef,
}: Props) {
  const activeOrganization = useActiveOrganization()!

  const { node } = usePreloadedQuery<RSVPToEventButtonModalPreloadedQuery>(
    RSVPToEventButtonPreloadedQuery,
    occurrenceQueryRef
  )

  const form = useFormStore<
    RecurringEventConfirmAttendanceModalMutation,
    RSVPToEventInput & { confirmAttendanceTo: ConfirmAttendanceTo }
  >(
    graphql`
      mutation RecurringEventConfirmAttendanceModalMutation($input: RSVPToEventInput!) {
        response: rsvpToEvent(input: $input) {
          occurrences {
            edges {
              node {
                hasViewerRSVPd
                atCapacity
                rsvps {
                  totalCount
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      confirmAttendanceTo: "single",
    },
    { requireChangeToSubmit: false }
  )

  const occurrence = Relay.narrowNodeType(node, "Occurrence")
  if (!occurrence) return null
  const occurrences = Relay.connectionToArray(occurrence.event.occurrences).filter(
    (o) => !o.isComplete
  )
  const totalOccurrences = occurrences.length
  const totalOccurrencesAtCapacity = occurrences.filter((o) => o.atCapacity).length
  return (
    <DiscoModal
      testid={`${testid}.recurring-event.confirm-attendance-modal`}
      isOpen={isOpen}
      title={"Confirm Attendance"}
      modalContentLabel={"Confirm Attendance"}
      onClose={onClose}
      buttons
      body={
        <Form
          onSubmit={handleSubmit}
          buttons={
            <Form.SubmitButton
              form={form}
              testid={`${testid}.recurring-event.confirm-attendance-modal.submit-button`}
            >
              {"Confirm Attendance"}
            </Form.SubmitButton>
          }
        >
          <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1.75}>
            {"This event is part of a recurring event. Confirm attendance to:"}
          </DiscoText>
          <RadioGroup value={form.state.confirmAttendanceTo} onChange={handleChange}>
            {/* Single */}
            <DiscoRadio
              testid={"RecurringEventConfirmAttendanceModal.Radio.this-event"}
              label={"This event only."}
              value={"single"}
              variant={"simple"}
              marginBottom={0}
            />

            {/* All */}
            <DiscoRadio
              testid={"RecurringEventConfirmAttendanceModal.Radio.all-events"}
              label={`All upcoming events (${totalOccurrences}) in the series.`}
              value={"all"}
              variant={"simple"}
              marginBottom={0}
            />
            {totalOccurrencesAtCapacity > 0 &&
              form.state.confirmAttendanceTo === "all" && (
                <DiscoAlert
                  marginTop={2.5}
                  severity={"info"}
                  message={`${totalOccurrencesAtCapacity}/${totalOccurrences} events are at max capacity. You can confirm attendance to these events later if the host increases the capacity.`}
                />
              )}
          </RadioGroup>
        </Form>
      }
    />
  )

  async function handleSubmit() {
    try {
      const { didSave } = await form.submit(
        form.state.confirmAttendanceTo === "single"
          ? { occurrenceId: occurrence!.id }
          : { eventId: occurrence!.event.id }
      )

      if (!didSave) return

      commitLocalUpdate(RelayEnvironment, (store) => {
        const org = store.get(activeOrganization.id)
        if (!org) return

        ConnectionHandler.getConnections(
          org,
          "RSVPToAllOrganizationEventsButton_occurrences"
        ).forEach((connection) => connection.invalidateRecord())
      })

      displaySuccessToast({
        message: "You confirmed attendance. Check your email for more details!",
        testid: `RecurringEventConfirmAttendanceModal.success-toast`,
      })

      onClose()
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    form.state.confirmAttendanceTo = e.target.value as ConfirmAttendanceTo
  }
}

export default observer(RecurringEventConfirmAttendanceModal)

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment RecurringEventConfirmAttendanceModalFragment on Occurrence {
    id
    event {
      id
      occurrences {
        edges {
          node {
            id
            isComplete
            atCapacity
          }
        }
      }
    }
  }
`
