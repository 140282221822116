import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { useUnreadThreadCountQuery } from "@components/chat/channel/util/__generated__/useUnreadThreadCountQuery.graphql"
import useDebounce from "@utils/hook/useDebounce"
import { useEffect } from "react"
import { graphql } from "relay-runtime"
import { useChatContext } from "stream-chat-react"

export default function useUnreadThreadCount() {
  const { client: streamClient } = useChatContext()!
  const activeOrganization = useActiveOrganization()!

  const [{ organization }, refetch] =
    Relay.useRefetchableBackgroundQuery<useUnreadThreadCountQuery>(
      graphql`
        query useUnreadThreadCountQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              id
              viewerUnreadThreadCount
            }
          }
        }
      `,
      { id: activeOrganization.id }
    )
  const unreadThreadCount = organization?.viewerUnreadThreadCount || 0

  // refetch unread thread count when new message is received, with a delay so that
  // it's more likely we have received the webhook from Stream by then
  const debouncedRefetch = useDebounce(refetch, 2000)
  useEffect(() => {
    if (!streamClient) return
    const { unsubscribe } = streamClient.on((event) => {
      if (event.type !== "message.new") return
      debouncedRefetch({ id: activeOrganization.id })
    })
    return unsubscribe
  }, [streamClient, debouncedRefetch, activeOrganization.id])

  return {
    unreadThreadCount,
    refetchUnreadThreadCount: () => refetch({ id: activeOrganization.id }),
  }
}
