import { useCommandTrigger } from "@components/chat/channel/trigger/hooks/useCommandTrigger"
import { useEmojiTrigger } from "@components/chat/channel/trigger/hooks/useEmojiTrigger"
import { useUserTrigger } from "@components/chat/channel/trigger/hooks/useUserTrigger"
import { FC } from "react"
import {
  MessageInputContextProvider,
  TriggerSettings,
  useMessageInputContext,
} from "stream-chat-react"

const ChatChannelTriggerProvider: FC = ({ children }) => {
  const currentValue = useMessageInputContext()

  const defaultAutocompleteTriggers: TriggerSettings = {
    "/": useCommandTrigger(),
    ":": useEmojiTrigger(currentValue.emojiIndex),
    "@": useUserTrigger({
      disableMentions: currentValue.disableMentions,
      mentionAllAppUsers: currentValue.mentionAllAppUsers,
      mentionQueryParams: currentValue.mentionQueryParams,
      onSelectUser: currentValue.onSelectUser,
      useMentionsTransliteration: currentValue.useMentionsTransliteration,
    }),
  }

  const newValue = {
    ...currentValue,
    autocompleteTriggers: defaultAutocompleteTriggers,
  }

  return (
    <MessageInputContextProvider value={newValue}>{children}</MessageInputContextProvider>
  )
}

export default ChatChannelTriggerProvider
