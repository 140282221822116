import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import classNames from "classnames"
import { ImageUpload, LoadingIndicator } from "stream-chat-react"

export type ImagePreviewerProps = {
  /** The list of image uploads that should be displayed */
  imageUploads?: ImageUpload[]
  /** A callback to call when the remove icon is clicked */
  handleRemove?: (id: string) => void
  /** A callback to call when the retry icon is clicked */
  handleRetry?: (id: string) => void
  /** A callback to call with newly selected files. If this is not provided no
   * `ThumbnailPlaceholder` will be displayed.
   */
  handleFiles?: (files: File[]) => void
  /** Allow drag 'n' drop (or selection from the file dialog) of multiple files */
  multiple?: boolean
  disabled?: boolean
}

/** Display a list of uploaded images
 * Based off the `react-file-utils` component
 * ref: https://github.com/GetStream/react-file-utils/blob/develop/src/components/ImagePreviewer.tsx
 */
export const ChatChannelImagePreviewer = ({
  imageUploads,
  handleRemove,
}: Partial<ImagePreviewerProps>) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <div className={classes.list}>
      {imageUploads?.map((image) => {
        const url = image.url || image.previewUri

        return (
          <div
            key={image.id}
            className={classNames(showOnHoverClasses.hoverable, classes.image)}
          >
            {/* Thumbnail */}
            {url && <img src={url} alt={image.title} className={classes.thumbnail} />}

            {/* Uploading */}
            {image.state === "uploading" && (
              <div className={classes.thumbnail}>
                <LoadingIndicator />
              </div>
            )}

            {/* Remove Button */}

            {handleRemove && (
              <ChatChannelRemoveAttachmentButton
                id={image.id}
                handleRemove={handleRemove}
                className={showOnHoverClasses.showable}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "flex",
  },
  image: {
    position: "relative",
    margin: theme.spacing(2, 2, 0, 0),
  },
  thumbnail: {
    borderRadius: theme.measure.borderRadius.medium,
    border: `1px solid ${theme.palette.groovy.grey[200]}`,
    height: "72px",
    width: "72px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}))
