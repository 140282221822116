import CheckIcon from "@/core/ui/iconsax/linear/custom-check.svg"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface AICheckProps extends TestIDProps {
  isChecked?: boolean
  hasError?: boolean
}

function AICheck({ hasError, isChecked, testid = "AICheck" }: AICheckProps) {
  const classes = useStyles()
  if (hasError)
    return (
      <div data-testid={testid} className={classNames(classes.root, classes.error)}>
        <CloseIcon width={"auto"} height={"auto"} />
      </div>
    )

  return (
    <div
      className={classNames(
        classes.root,
        isChecked ? classes.checked : classes.unchecked
      )}
    >
      {isChecked ? <CheckIcon /> : null}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    borderRadius: "50%",
    height: 24,
    width: 24,
    padding: theme.spacing(0.25),
    display: "inline-block",
  },
  checked: {
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurple02
        : theme.palette.aiGradient.bluePurple03,
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  unchecked: {
    backgroundColor: theme.palette.background.paper,
    border: `1.5px solid ${theme.palette.divider}`,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
}))

export default AICheck
