import { DirectMessagesListChatChannel } from "@/admin/community/direct-messages/CommunityDirectMessagesPageContent"
import { useStreamChat } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DirectMessagesListItem, {
  DirectMessagesListItemSkeleton,
} from "@components/chat/channel/list/DirectMessagesListItem"
import { DiscoDivider, DiscoText, DiscoTextButton } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { range } from "@utils/array/arrayUtils"
import React from "react"

type Props = {
  variant: "sidebar" | "page"
  chatChannels: DirectMessagesListChatChannel[]
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
}

function DirectMessagesList(props: Props) {
  const { variant, chatChannels, isLoading, hasMore, loadMore } = props
  const classes = useStyles()
  const { isConnected } = useStreamChat()

  if (!isConnected) return null

  return (
    <div className={classes.list}>
      {chatChannels.length ? (
        chatChannels.map((chatChannel) => (
          <DirectMessagesListItem
            key={chatChannel.id}
            chatChannelKey={chatChannel}
            variant={variant}
          />
        ))
      ) : (
        <>
          <DiscoDivider marginTop={0} marginBottom={2} />
          <DiscoText variant={"body-sm"} color={"groovy.neutral.400"} align={"center"}>
            {"No direct messages yet."}
          </DiscoText>
        </>
      )}
      {hasMore && renderPaginator()}
    </div>
  )

  function renderPaginator() {
    // Sidebar links to the DM page instead of paginating inline
    if (variant === "sidebar")
      return (
        <DiscoTextButton
          to={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.ROOT}
          textVariant={"body-sm-600"}
          className={classes.viewAllLink}
        >
          {"View All Messages"}
        </DiscoTextButton>
      )
    return (
      <DiscoScrolledIntoView
        isLoading={isLoading}
        onScrolledIntoView={loadMore}
        skeleton={
          <>
            {range(4).map((i) => (
              <DirectMessagesListItemSkeleton key={i} variant={variant} />
            ))}
          </>
        }
      />
    )
  }
}

export function DirectMessagesListSkeleton(props: Pick<Props, "variant">) {
  const { variant } = props
  const classes = useStyles()

  return variant === "sidebar" ? (
    <div className={classes.skeletonWrapper}>
      <DirectMessagesListItemSkeleton variant={variant} />
    </div>
  ) : (
    <div>
      {range(8).map((i) => (
        <DirectMessagesListItemSkeleton key={i} variant={variant} />
      ))}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    width: "100%",
    height: "100%",
  },
  viewAllLink: {
    width: "100%",
  },
  skeletonWrapper: {
    padding: theme.spacing(0.5, 0),
  },
}))

export default React.memo(DirectMessagesList)
