import { useDrawerContext } from "@/core/context/DrawerContext"
import MessageIcon from "@/core/ui/iconsax/linear/message-text.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelEmojiButton from "@components/chat/channel/emoji-button/ChatChannelEmojiButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import ChatChannelMessageActions from "@components/chat/channel/message-actions/ChatChannelMessageActions"
import { defaultReactions } from "@components/chat/channel/util/chatChannelConstants"
import { DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import classNames from "classnames"
import { EmojiData } from "emoji-mart"
import { useCallback } from "react"
import { showMessageActionsBox, useMessageContext } from "stream-chat-react"

interface Props {
  className?: string
}

function ChatChannelMessageOptions(props: Props) {
  const { className } = props

  const {
    customMessageActions,
    getMessageActions,
    handleOpenThread,
    initialMessage: threadHeader,
    message,
    handleReaction,
    threadList: isInThread,
    handlePin,
  } = useMessageContext()

  const classes = useStyles({ threadHeader })
  const messageActions = getMessageActions()
  const showActionsBox = showMessageActionsBox(messageActions) || !!customMessageActions
  const permissions = useActiveProductOrOrganizationPermissions()
  const { closeDrawer } = useDrawerContext()

  const handleEmojiClick = useCallback(
    (emoji: EmojiData) => {
      if (emoji.id) {
        handleReaction(emoji.id, undefined as any)
      }
    },
    [handleReaction]
  )

  if (
    !message.type ||
    message.type === "error" ||
    message.type === "system" ||
    message.type === "ephemeral" ||
    message.status === "failed" ||
    message.status === "sending"
  ) {
    return null
  }

  return (
    <div
      className={classNames(classes.optionsContainer, className)}
      data-testid={"message-options"}
    >
      {/* Default Reactions */}
      {defaultReactions.map((emoji) => (
        <ChatChannelEmojiButton key={emoji.id} emoji={emoji} />
      ))}

      {/* Emoji Picker */}
      <ChatChannelEmojiPickerDropdown onEmojiSelect={handleEmojiClick} />

      {/* Pin */}
      {messageActions.includes("pin") && permissions.has("chat.manage") && (
        <DiscoTooltip content={message.pinned ? "Unpin Message" : "Pin Message"}>
          <DiscoIconButton onClick={handlePin} className={classes.icon} size={"small"}>
            <DiscoIcon icon={"pin"} active={message.pinned} />
          </DiscoIconButton>
        </DiscoTooltip>
      )}

      {/* Thread */}
      {message.type !== "reply" && !isInThread && (
        <DiscoTooltip content={"Reply in thread"}>
          <DiscoIconButton
            testid={"message-options.reply-in-thread"}
            onClick={(e) => handleReplyInThread(e)}
            className={classes.icon}
            size={"small"}
          >
            <MessageIcon />
          </DiscoIconButton>
        </DiscoTooltip>
      )}

      {/* More Actions */}
      {showActionsBox && (
        <DiscoTooltip content={"More actions"}>
          <div>
            <ChatChannelMessageActions />
          </div>
        </DiscoTooltip>
      )}
    </div>
  )

  function handleReplyInThread(event: React.MouseEvent<Element, MouseEvent>) {
    if (closeDrawer) closeDrawer()
    handleOpenThread(event)
  }
}

type StyleProps = {
  threadHeader?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  optionsContainer: ({ threadHeader }: StyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.boxShadow,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.medium,
    position: "absolute",
    top: threadHeader ? "15px" : "-5px",
    right: threadHeader ? "16px" : "10px",
    zIndex: 2,
    padding: theme.spacing(1, 0.5),
    height: "40px",
  }),
  icon: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,

    "& svg": {
      color: theme.palette.text.secondary,
    },
  },
}))

export default ChatChannelMessageOptions
