import Badge, { BadgeSize } from "@/admin/experiences/badges/Badge"
import { CommunityBadgeFragment$key } from "@/community/__generated__/CommunityBadgeFragment.graphql"
import ColorUtils from "@assets/style/util/ColorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

interface CommunityBadgeProps extends TestIDProps {
  organizationKey: CommunityBadgeFragment$key
  size?: BadgeSize
  customClassName?: string
  classes?: Partial<ClassNameMap<"badge">>
}

export default function CommunityBadge({
  testid = "CommunityBadge",
  organizationKey,
  size = 48,
  customClassName,
  classes: customClasses,
}: CommunityBadgeProps) {
  const { name, badge } = useFragment<CommunityBadgeFragment$key>(
    graphql`
      fragment CommunityBadgeFragment on Organization {
        name
        badge {
          id
          kind
          icon
          color
          ...BadgeFragment
        }
      }
    `,
    organizationKey
  )

  const theme = useTheme()
  const classes = useStyles()

  const acronymColor = ColorUtils.getContrastColor(
    theme,
    badge?.color || theme.palette.common.white
  )
  const showAcronym = badge?.kind === "icon" && !badge?.icon

  return (
    <div data-testid={testid} className={classNames(classes.badge, customClassName)}>
      <Badge
        badgeKey={badge!}
        size={size}
        fallbackColor={theme.palette.groovy.neutral[100]}
        className={customClasses?.badge}
      />
      {showAcronym && (
        <DiscoText className={classes.acronym} setColor={acronymColor}>
          {getInitialLettersOfWords(name).slice(0, 2)}
        </DiscoText>
      )}
    </div>
  )
}

const useStyles = makeUseStyles({
  badge: {
    position: "relative",
    width: "max-content",
  },
  acronym: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
})
