import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"
import React from "react"

interface ContentBoxProps {
  children: React.ReactNode
  withShadowDrop?: boolean
  customClassName?: string
}

function ContentBox({ children, customClassName, withShadowDrop }: ContentBoxProps) {
  const classes = useStyles()
  return (
    <div
      className={classNames(classes.container, customClassName, {
        [classes.shadow]: withShadowDrop,
      })}
    >
      {children}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    borderRadius: theme.measure.borderRadius.large,
  },
  shadow: {
    border: "none",
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
}))

export default ContentBox
