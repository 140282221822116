import ChatChannelSettingsModalButton from "@/apps/list/app/chat/button/ChatChannelSettingsModalButton"
import DeleteChatChannelAppButton from "@/apps/list/app/chat/button/DeleteChatChannelAppButton"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import HeaderContent from "@/main/page/header/HeaderContent"
import Relay from "@/relay/relayUtils"
import { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelBadge from "@components/chat/badge/ChatChannelBadge"
import ChatChannelAvatarStack from "@components/chat/channel/detail/avatar-stack/ChatChannelAvatarStack"
import {
  ChatChannelHeaderFragment$data,
  ChatChannelHeaderFragment$key,
} from "@components/chat/channel/detail/header/__generated__/ChatChannelHeaderFragment.graphql"
import ChatChannelPinnedMessagesDrawer from "@components/chat/channel/pinned-messages/ChatChannelPinnedMessagesDrawer"
import { DiscoIcon, DiscoIconButton, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

interface ChatChannelHeaderProps extends TestIDProps {
  chatChannelKey: ChatChannelHeaderFragment$key
}

const ChatChannelHeader: React.FC<ChatChannelHeaderProps> = ({
  chatChannelKey,
  testid = "ChatChannelHeader",
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const chatChannel = useFragment<ChatChannelHeaderFragment$key>(
    graphql`
      fragment ChatChannelHeaderFragment on ChatChannel {
        id
        kind
        visibility
        product {
          id
          slug
          name
        }
        app {
          id
          customAppTitle
          customAppDescription
          ...HeaderContentFragment
          ...ChatChannelSettingsModalButtonFragment
          ...ChatChannelBadgeFragment
          ...DeleteChatChannelAppButtonFragment
        }
        chatChannelMembers(first: 5) {
          totalCount
          edges {
            node {
              id
              user {
                id
                first_name: firstName
                last_name: lastName
                avatar
              }
              productMembership {
                role
              }
              organizationMembership {
                role
              }
            }
          }
        }
        ...usePermissionsFragment
      }
    `,
    chatChannelKey
  )

  const isDM = chatChannel.kind === "direct_message"
  const isPrivate = chatChannel.visibility === "private"
  const permissions = usePermissions(chatChannel)
  const canManage = permissions.has("chat.manage")

  return (
    <HeaderContent
      appKey={chatChannel.app}
      leftIcon={
        chatChannel.app && (
          <ChatChannelBadge
            appKey={chatChannel.app}
            className={classes.icon}
            badgeSize={40}
          />
        )
      }
      title={
        <div>
          <DiscoText
            variant={"body-md-500"}
            truncateText={1}
            testid={"HeaderContent.title"}
          >
            {isDM ? "Direct Message" : chatChannel.app?.customAppTitle}
          </DiscoText>
          {chatChannel.product && (
            <DiscoText variant={"body-xs"} component={"div"}>
              {"in "}
              <DiscoLink
                to={generatePath(ROUTE_NAMES.PRODUCT.ROOT, {
                  productSlug: chatChannel.product.slug,
                })}
                textVariant={"body-xs-500"}
              >
                {chatChannel.product.name}
              </DiscoLink>
            </DiscoText>
          )}
        </div>
      }
      descriptionTooltip={chatChannel.app?.customAppDescription}
      rightOptions={
        <>
          {isPrivate && (
            <DiscoTag
              testid={"ChatChannelHeader.private-channel"}
              name={"Private"}
              backgroundColor={theme.palette.groovy.neutral[100]}
            />
          )}

          <ChatChannelAvatarStack
            data-testid={"ChatChannelHeader.member-stack"}
            users={mapChannelMembersToAvatarUsers(chatChannel.chatChannelMembers)}
            totalUsers={chatChannel.chatChannelMembers.totalCount}
            channelId={chatChannel.id}
            channelAppId={
              !isDM && isPrivate && canManage && chatChannel.app
                ? chatChannel.app.id
                : undefined
            }
            countVariant={"always"}
            listOnly
          />

          <DiscoTooltip content={"Pinned Messages"}>
            <DiscoIconButton onClick={onOpen} size={"small"}>
              <DiscoIcon icon={"pin"} />
            </DiscoIconButton>
          </DiscoTooltip>

          <ChatChannelPinnedMessagesDrawer isOpen={isOpen} onClose={onClose} />

          {chatChannel.app && canManage && (
            <DiscoMoreActionsDropdown testid={testid}>
              <ActiveAppModalProvider>
                <ChatChannelSettingsModalButton appKey={chatChannel.app}>
                  {(buttonProps) => (
                    <DiscoDropdownItem
                      {...buttonProps}
                      title={"Edit"}
                      testid={`${testid}.overflow.edit`}
                    />
                  )}
                </ChatChannelSettingsModalButton>
              </ActiveAppModalProvider>
              <DeleteChatChannelAppButton appKey={chatChannel.app}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    title={"Remove"}
                    testid={`${testid}.overflow.remove`}
                  />
                )}
              </DeleteChatChannelAppButton>
            </DiscoMoreActionsDropdown>
          )}
        </>
      }
    />
  )

  function mapChannelMembersToAvatarUsers(
    chatChannelMembers: ChatChannelHeaderFragment$data["chatChannelMembers"]
  ): UserAvatarShape[] {
    const admins: UserAvatarShape[] = []
    const learners: UserAvatarShape[] = []
    const members = Relay.connectionToArray(chatChannelMembers)

    members.forEach((member) => {
      // Filter out org owners/admins in a product w/o membership
      if (!member.productMembership && chatChannel.product?.id) return

      if (
        member.productMembership?.role === "manager" ||
        member.productMembership?.role === "instructor" ||
        member.organizationMembership?.role === "owner" ||
        member.organizationMembership?.role === "admin"
      ) {
        admins.push(member.user)
      } else {
        learners.push(member.user)
      }
    })

    // Sort the members
    return [...admins, ...learners]
  }
}

const useStyles = makeUseStyles((theme) => ({
  icon: {
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
}))

export default ChatChannelHeader
