import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import ChatChannelEmpty from "@components/chat/channel/empty/ChatChannelEmpty"
import { MessageList, useChannelStateContext } from "stream-chat-react"

function ChatChannelPinnedMessagesList() {
  const classes = useStyles()

  const { pinnedMessages } = useChannelStateContext()

  return (
    <div className={classes.container}>
      {pinnedMessages?.length ? (
        <MessageList
          messages={pinnedMessages}
          Message={ChatChannelMessage}
          disableDateSeparator
          disableQuotedMessages
          hideNewMessageSeparator
          noGroupByUser
        />
      ) : (
        <ChatChannelEmpty state={"pinned"} />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1),

    "& .str-chat__list .str-chat__reverse-infinite-scroll": {
      padding: 0,
    },

    "& .str-chat__li--single": {
      margin: 0,
    },
  },
}))

export default ChatChannelPinnedMessagesList
