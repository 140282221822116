import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextProps } from "@disco-ui"

type AITextProps = DiscoTextProps

function AIText(props: AITextProps) {
  const { children, ...rest } = props
  const classes = useStyles()
  return (
    <DiscoText
      {...rest}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </DiscoText>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    backgroundImage:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurple02
        : theme.palette.aiGradient.bluePurple03,
    backgroundClip: "text",
    textFillColor: "transparent",
  },
}))

export default AIText
