import CommunitySwitcherCreateCommunityButton from "@/community/switcher/CommunitySwitcherCreateCommunityButton"
import CommunitySwitcherListItem from "@/community/switcher/CommunitySwitcherListItem"
import { CommunitySwitcherListItemTemplateSkeleton } from "@/community/switcher/CommunitySwitcherListItemTemplate"
import { CommunitySwitcherListQuery } from "@/community/switcher/__generated__/CommunitySwitcherListQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider } from "@disco-ui"
import DiscoPoweredByLogo from "@disco-ui/logos/DiscoPoweredByLogo"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

function CommunitySwitcherList() {
  const activeOrganization = useActiveOrganization()
  const { viewer } = useLazyLoadQuery<CommunitySwitcherListQuery>(
    graphql`
      query CommunitySwitcherListQuery {
        viewer {
          id
          organizationMemberships(field: creation_datetime, direction: DESC) {
            edges {
              node {
                id
                organizationId
                ...CommunitySwitcherListItemFragment
              }
            }
          }
        }
      }
    `,
    {}
  )

  const memberships = Relay.connectionToArray(viewer?.organizationMemberships)
  const activeOrgIndex = memberships.findIndex(
    (m) => m.organizationId === activeOrganization?.id
  )
  const activeOrgMemebership = memberships.splice(activeOrgIndex, 1)[0]

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {/* show the active org at top */}
        <CommunitySwitcherListItem
          key={activeOrgMemebership.id}
          testid={`CommunitySwitcherListItem.membership-${0}`}
          membershipKey={activeOrgMemebership}
        />
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <div className={classes.list}>
        {memberships.map((membership, i) => (
          <CommunitySwitcherListItem
            key={membership.id}
            testid={`CommunitySwitcherListItem.membership-${i + 1}`}
            membershipKey={membership}
          />
        ))}
        <CommunitySwitcherCreateCommunityButton />
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <DiscoPoweredByLogo />
    </div>
  )
}

const CommunitySwitcherListSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.list}>
      <CommunitySwitcherListItemTemplateSkeleton />
      <CommunitySwitcherListItemTemplateSkeleton />
      <CommunitySwitcherListItemTemplateSkeleton />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.25, CommunitySwitcherListConstants.HORIZONTAL_PADDING),
  },
  container: {
    width: CommunitySwitcherListConstants.WIDTH,
    display: "grid",
    gridTemplateRows: "auto auto 1fr auto auto",
  },
}))
export namespace CommunitySwitcherListConstants {
  export const HORIZONTAL_PADDING = 1.5
  export const WIDTH = 296
}

export default Relay.withSkeleton({
  component: CommunitySwitcherList,
  skeleton: CommunitySwitcherListSkeleton,
})
