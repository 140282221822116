import useStartDirectMessage from "@/chat/hooks/useStartDirectMessage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import OrganizationMemberMultiSelect from "@/organization/member/common/OrganizationMemberMultiSelect"
import { MemberMultiSelect } from "@/product/member/common/ProductMemberMultiSelect"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import Form from "../../form/Form"

interface NewDirectMessageModalProps {
  isOpen: boolean
  onClose: () => void
}

const NewDirectMessageModal = observer<NewDirectMessageModalProps>(
  ({ isOpen, onClose }) => {
    const [selectedMembers, setSelectedMembers] = useState<MemberMultiSelect[]>([])

    const { authUser } = useAuthUser({ required: true })

    const activeOrganization = useActiveOrganization()
    const [startDirectMessage, isSubmitting] = useStartDirectMessage()

    const memberLabel = useLabel("organization_member")
    return (
      <DiscoModal
        isOpen={isOpen}
        onClose={onClose}
        modalContentLabel={"new conversation modal"}
        maxWidth={"560px"}
        title={"Start a Direct Message"}
        testid={"NewDirectMessageModal"}
        buttons
        body={
          <Form
            testid={"NewDirectMessageModal.Form"}
            isSubmitAllowed={selectedMembers.length > 0}
            onSubmit={handleSubmit}
            buttons={
              <>
                <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
                  {"Cancel"}
                </DiscoButton>

                <Form.SubmitButton
                  form={{ isSubmitting, disabled: selectedMembers.length === 0 }}
                  testid={"NewDirectMessageModal.SubmitButton"}
                >
                  {"Start Conversation"}
                </Form.SubmitButton>
              </>
            }
          >
            {activeOrganization?.viewerMembership ? (
              <OrganizationMemberMultiSelect
                testid={"OrganizationMemberSelect.search"}
                values={selectedMembers.map((m) => m.id)}
                onChange={handleChangeMembership}
                hideUserIds={[authUser.id]}
                autoFocusInput
              />
            ) : (
              <DiscoText>
                {`You must be a ${memberLabel.singular} of this community to start a conversation.`}
              </DiscoText>
            )}
          </Form>
        }
      />
    )

    function handleChangeMembership(memberships: MemberMultiSelect[]) {
      setSelectedMembers([...memberships])
    }

    async function handleSubmit() {
      const orgMemberIds = selectedMembers.map((m) => m.id)
      if (!orgMemberIds.length) return
      const success = await startDirectMessage(orgMemberIds)
      if (success) onClose()
    }
  }
)

export default NewDirectMessageModal
