import classNames from "classnames"
import { EmojiData } from "emoji-mart"
import React, { useCallback } from "react"
import {
  AutoCompleteTextarea,
  ChatAutoCompleteProps,
  LoadingIndicator,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react"

function MessageInputAutoComplete(
  props: ChatAutoCompleteProps & {
    maxRows?: number
    grow?: boolean
    customClassName?: string
  }
) {
  const {
    AutocompleteSuggestionItem: SuggestionItem,
    AutocompleteSuggestionList: SuggestionList,
  } = useComponentContext()

  const {
    handleSubmit,
    onChange,
    onPaste,
    value,
    maxRows,
    placeholder,
    rows,
    onFocus,
    grow,
    customClassName,
  } = props

  const messageInput = useMessageInputContext()
  const { cooldownRemaining, disabled, emojiIndex, textareaRef: innerRef } = messageInput

  const emojiReplace = (word: string) => {
    const found = emojiIndex?.search(word) || []
    const emoji = found
      .filter(Boolean)
      .slice(0, 10)
      .find(({ emoticons }: EmojiData) => !!emoticons?.includes(word))
    if (!emoji || !("native" in emoji)) return null
    return emoji.native
  }

  const updateInnerRef = useCallback(
    (ref) => {
      if (innerRef) {
        innerRef.current = ref
      }
    },
    [innerRef]
  )

  // Limit paste to 5000 characters
  const textValue =
    value?.slice(undefined, 5000) || messageInput.text.slice(undefined, 5000)

  return (
    <AutoCompleteTextarea
      additionalTextareaProps={{
        ...messageInput.additionalTextareaProps,
        maxLength: 5000,
      }}
      className={classNames("str-chat__textarea__textarea", customClassName)}
      closeCommandsList={messageInput.closeCommandsList}
      containerClassName={"str-chat__textarea"}
      disabled={disabled || !!cooldownRemaining}
      disableMentions={messageInput.disableMentions}
      dropdownClassName={"str-chat__emojisearch"}
      grow={grow || messageInput.grow}
      handleSubmit={handleSubmit || messageInput.handleSubmit}
      innerRef={updateInnerRef}
      itemClassName={"str-chat__emojisearch__item"}
      shouldSubmit={messageInput.shouldSubmit}
      listClassName={"str-chat__emojisearch__list"}
      loadingComponent={LoadingIndicator}
      maxRows={maxRows || messageInput.maxRows}
      minChar={0}
      onChange={onChange || messageInput.handleChange}
      onFocus={onFocus}
      onPaste={onPaste || messageInput.onPaste}
      placeholder={cooldownRemaining ? "Slow Mode ON" : placeholder}
      replaceWord={emojiReplace}
      rows={rows || 1}
      showCommandsList={messageInput.showCommandsList}
      SuggestionItem={SuggestionItem}
      SuggestionList={SuggestionList}
      trigger={messageInput.autocompleteTriggers || {}}
      value={textValue}
      minRows={rows || 1}
    />
  )
}

export default React.memo(MessageInputAutoComplete)
