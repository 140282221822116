import { sendSentryAnException } from "@/core/sentryHandler"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelEmojiButton from "@components/chat/channel/emoji-button/ChatChannelEmojiButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { EmojiData } from "emoji-mart"
import { useCallback, useRef, useState } from "react"
import { ReactionResponse } from "stream-chat"
import { useChannelStateContext, useMessageContext } from "stream-chat-react"

const REACTION_FETCH_LIMIT = 300

const ChatChannelReactionsList = () => {
  const { message, handleReaction } = useMessageContext()
  const { channel } = useChannelStateContext()
  const classes = useStyles()
  const [reactions, setReactions] = useState(message.latest_reactions || [])
  const reactionsLoadingRef = useRef(false)

  const ownReactionsTypes = (message.own_reactions || []).map((reaction) => reaction.type)

  const handleEmojiClick = useCallback(
    (emoji: EmojiData) => {
      if (emoji.id) {
        handleReaction(emoji.id, undefined as any)
      }
    },
    [handleReaction]
  )

  if (!message.reaction_counts || Object.keys(message.reaction_counts).length === 0)
    return null

  return (
    <ol className={classes.list}>
      {Object.keys(message.reaction_counts)
        .sort()
        .map((type) => (
          <li key={type}>
            <ChatChannelEmojiButton
              variant={"reaction-list"}
              emoji={type}
              reacted={ownReactionsTypes.includes(type)}
              reactionCount={message.reaction_counts![type]}
              reactions={reactions.filter((r) => r.type === type)}
              loadReactions={loadReactions}
            />
          </li>
        ))}

      <li>
        <ChatChannelEmojiPickerDropdown onEmojiSelect={handleEmojiClick} />
      </li>
    </ol>
  )

  async function loadReactions() {
    if (reactionsLoadingRef.current || !message.reaction_counts) return
    reactionsLoadingRef.current = true
    const totalCount = Object.values(message.reaction_counts).reduce(
      (sum, count) => sum + count
    )
    try {
      let newReactions: ReactionResponse[] = []
      let offset = 0
      do {
        const res = await channel.getReactions(message.id, {
          limit: REACTION_FETCH_LIMIT,
          offset,
        })
        newReactions = newReactions.concat(res.reactions)
        offset += REACTION_FETCH_LIMIT
      } while (offset < totalCount)
      setReactions(newReactions)
    } catch (error) {
      sendSentryAnException(error)
      displayErrorToast("Unable to get reactions, please try again later")
    }
    reactionsLoadingRef.current = false
  }
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    margin: theme.spacing(0.5, 0),
    gap: theme.spacing(0.5),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "& li": {
      listStyle: "none",
      margin: theme.spacing(0.25, 0),
    },
  },
}))

export default ChatChannelReactionsList
