import { AiApiGenerateMessageTemplate } from "@/common/AiApi"
import AIButton from "@components/ai/AIButton"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import useHasEntitlement from "@utils/hook/useHasEntitlement"

interface Props {
  channelId: string
  template?: AiApiGenerateMessageTemplate
  children?: OverridableDiscoButtonChildren
}

export type CreateMessageWithAIQueryParams = {
  aiGid?: string // AI GID - target chat channel gid for the AI generated message
  aiTemplate?: "inactive" | "default"
}

function CreateMessageWithAIButton({ channelId, template = "default", children }: Props) {
  const hasEntitlement = useHasEntitlement("ai_inline_prompts")
  const [{ aiGid, aiTemplate }, setParams] =
    useQueryParamState<CreateMessageWithAIQueryParams>()

  if (!hasEntitlement) return null

  return (
    <OverridableDiscoButton
      onClick={handleCreateMessageWithAI}
      stopPropagation
      spinnerProps={{ fullWidth: false }}
      disabled={aiGid === channelId}
      shouldDisplaySpinner={aiGid === channelId && aiTemplate === template}
    >
      {children ||
        ((buttonProps) => {
          return <AIButton {...buttonProps}>{"Create Message with AI"}</AIButton>
        })}
    </OverridableDiscoButton>
  )

  function handleCreateMessageWithAI() {
    setParams({
      aiGid: channelId,
      aiTemplate: template === "inactive" ? "inactive" : "default",
    })
  }
}

export default CreateMessageWithAIButton
