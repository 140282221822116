import useDisclosure from "@utils/hook/useDisclosure"
import { useEffect, useRef, useState } from "react"
import { useChannelActionContext, useChatContext } from "stream-chat-react"

function ThreadButton({ messageId }: { messageId: string }) {
  const { openThread } = useChannelActionContext()
  const { isOpen, onOpen } = useDisclosure()
  // Has the request to open the thread started?
  const [started, setStarted] = useState(false)
  const { client } = useChatContext()

  const buttonRef = useRef<HTMLButtonElement>(null)

  // Open Thread
  useEffect(() => {
    if (!client || !buttonRef.current || !openThread || isOpen || started) return
    buttonRef.current.click()
  }, [openThread, isOpen, client, started])

  return <button ref={buttonRef} style={{ display: "none" }} onClick={handleOpenThread} />

  async function handleOpenThread(e: React.BaseSyntheticEvent) {
    if (!openThread || !client || isOpen || started) return

    setStarted(true)

    try {
      const payload = await client.getMessage(messageId)
      openThread(payload.message, e)
      onOpen()
    } finally {
      setStarted(false)
    }
  }
}

export default ThreadButton
