import Badge, { BadgeSize } from "@/admin/experiences/badges/Badge"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ChatChannelBadgeFragment$key } from "@components/chat/badge/__generated__/ChatChannelBadgeFragment.graphql"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ChatChannelBadgeProps extends TestIDProps {
  appKey: ChatChannelBadgeFragment$key
  badgeSize?: BadgeSize
  className?: string
  isSelected?: boolean
  isSideBar?: boolean
}

function ChatChannelBadge({
  appKey,
  badgeSize = 24,
  className,
  isSelected = false,
  isSideBar = false,
}: ChatChannelBadgeProps) {
  const classes = useStyles()

  const productApp = useFragment<ChatChannelBadgeFragment$key>(
    graphql`
      fragment ChatChannelBadgeFragment on ProductApp {
        badge {
          ...BadgeFragment
        }
        chatChannel {
          visibility
        }
      }
    `,
    appKey
  )

  if (!productApp.chatChannel || !productApp.badge) return null

  return (
    <div className={classes.relativeParent}>
      <Badge
        isSideBar={isSideBar}
        isSelected={isSelected}
        badgeKey={productApp.badge}
        size={badgeSize}
        className={className}
      />
    </div>
  )
}

const useStyles = makeUseStyles({
  relativeParent: {
    position: "relative",
  },
})

export default ChatChannelBadge
