import Relay from "@/relay/relayUtils"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelAvatarStack from "@components/chat/channel/detail/avatar-stack/ChatChannelAvatarStack"
import { DirectMessageHeaderFragment$key } from "@components/chat/channel/detail/header/__generated__/DirectMessageHeaderFragment.graphql"
import { DiscoText, GroovyTextColorKind } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface DirectMessageHeaderProps extends TestIDProps {
  channelKey: DirectMessageHeaderFragment$key
  textColor?: GroovyTextColorKind
  textVariant?: TextVariantWithModifiers
  avatarSize?: ProfileAvatarSize
}

function DirectMessageHeader(props: DirectMessageHeaderProps) {
  const {
    channelKey,
    textColor,
    textVariant,
    avatarSize,
    testid = "DirectMessageHeader",
  } = props

  const classes = useStyles()
  const chatChannel = useFragment<DirectMessageHeaderFragment$key>(
    graphql`
      fragment DirectMessageHeaderFragment on ChatChannel {
        id
        chatChannelMembers(first: 5, excludeViewer: true) {
          edges {
            node {
              id
              user {
                id
                first_name: firstName
                last_name: lastName
                fullName
                avatar
              }
            }
          }
        }
      }
    `,
    channelKey
  )

  const users = Relay.connectionToArray(chatChannel.chatChannelMembers).map((m) => m.user)
  const chatChannelName = users.map((u) => u.fullName).join(", ")

  return (
    <div className={classes.channelNameGroup}>
      <ChatChannelAvatarStack
        users={users}
        channelId={chatChannel.id}
        countVariant={"placeholder"}
        avatarSize={avatarSize || 40}
      />
      <DiscoText
        variant={textVariant || "body-md-600"}
        testid={`${testid}.header-title.${chatChannelName}`}
        color={textColor}
      >
        {chatChannelName}
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  channelNameGroup: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}))

export default DirectMessageHeader
