import Relay from "@/relay/relayUtils"
import ProfilePopover from "@/user/common/profile-popover/ProfilePopover"
import { ChatChannelClickedUserPopoverQuery } from "@components/chat/channel/clicked-user/__generated__/ChatChannelClickedUserPopoverQuery.graphql"
import React from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { UserResponse } from "stream-chat"

type ChatChannelClickedUserPopoverProps = {
  clickedUser: UserResponse
  setClickedUser: React.Dispatch<React.SetStateAction<UserResponse | undefined>>
  clickedUserTarget: HTMLButtonElement
}

const ChatChannelClickedUserPopover = ({
  clickedUser,
  setClickedUser,
  clickedUserTarget,
}: ChatChannelClickedUserPopoverProps) => {
  const { organizationMembership } = useLazyLoadQuery<ChatChannelClickedUserPopoverQuery>(
    graphql`
      query ChatChannelClickedUserPopoverQuery($id: ID!) {
        organizationMembership: node(id: $id) {
          id
          ... on OrganizationMembership {
            memberId
          }
        }
      }
    `,
    {
      id: Relay.toGlobalId(
        "OrganizationMembership",
        clickedUser.organization_membership_id as string
      ),
    }
  )

  if (!organizationMembership?.memberId) return null

  return (
    <ProfilePopover
      userId={organizationMembership.memberId}
      anchorEl={clickedUserTarget}
      onClose={onClose}
    />
  )

  function onClose() {
    setClickedUser(undefined)
  }
}

const ChatChannelClickedUserPopoverSkeleton: React.FC = () => {
  return null
}

export default Relay.withSkeleton<ChatChannelClickedUserPopoverProps>({
  component: ChatChannelClickedUserPopover,
  skeleton: ChatChannelClickedUserPopoverSkeleton,
})
