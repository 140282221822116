import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoButtonProps } from "@disco-ui"
import classnames from "classnames"

type AIButtonProps = Omit<DiscoButtonProps, "color">

function AIButton(props: AIButtonProps) {
  const { children, ...rest } = props
  const classes = useStyles()

  return (
    <DiscoButton
      {...rest}
      leftIcon={"stars"}
      className={classnames(classes.container, rest.className)}
    >
      {children}
    </DiscoButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    background: theme.palette.aiGradient.bluePurple03,
  },
}))

export default AIButton
