import { useLabel } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import WarningIcon from "@assets/disco/icons/color-icons/warning-light.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import {
  AttachBlockEntity,
  ATTACH_BLOCK_CONFIG,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import classNames from "classnames"
import React from "react"

export declare type Props = {
  type: AttachBlockEntity
  entityId: string
  handleRemove?: (id: string) => void
}

const ChatChannelAttachBlock = ({ type, entityId, handleRemove }: Props) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const membersLabel = useLabel("organization_member")
  const productLabel = useLabel("admin_experience")

  const attachBlockTitle = getAttachBlockTitle()

  const config = ATTACH_BLOCK_CONFIG[type]
  const component = React.createElement(config.Component, {
    entityId,
  })

  return (
    <div className={classes.container}>
      <div className={classNames(showOnHoverClasses.hoverable, classes.list)}>
        <div key={entityId} className={classes.item}>
          <DiscoContainerButton className={classes.containerButton} disabled>
            {component}
          </DiscoContainerButton>
          {handleRemove && (
            <ChatChannelRemoveAttachmentButton
              id={entityId}
              handleRemove={handleRemove}
              className={showOnHoverClasses.showable}
            />
          )}
        </div>
      </div>
      <div className={classes.warning}>
        <WarningIcon width={24} height={24} />
        <DiscoText variant={"body-sm"} noWrap truncateText={1}>
          {`Only ${membersLabel.plural} with access to this ${attachBlockTitle} will be able to view it.`}
        </DiscoText>
      </div>
    </div>
  )

  function getAttachBlockTitle() {
    switch (type) {
      case "contentUsage":
        return "content"
      case "occurrence":
        return "event"
      case "product":
        return productLabel.singular
      default:
        return type
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(2),
    gap: theme.spacing(1),
  },
  list: {
    display: "flex",
  },
  item: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1, 1, 1, 0),
    flexShrink: 0,
    position: "relative",
    margin: theme.spacing(1, 0, 0.5, 0),
    width: "100%",
  },
  warning: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginLeft: theme.spacing(1),
  },
  containerButton: {
    backgroundColor: "transparent",
    opacity: 1,
    width: "100%",
    "& button": {
      opacity: 0.4,
    },
  },
}))

export default Relay.withSkeleton({
  component: ChatChannelAttachBlock,
  skeleton: () => null,
})
