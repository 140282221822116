import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoImage from "@disco-ui/image/DiscoImage"
import classNames from "classnames"
import { ReactNode } from "react"

interface AvatarProps {
  alt: string
  src: string | undefined | null
  placeholder?: ReactNode
  className?: string
  size?: number
  /** If true, apply a drop shadow on the component */
  dropShadow?: boolean
  shape?: AvatarShape
}

export type AvatarShape = "circle" | "square"

function Avatar({
  alt,
  src,
  className,
  placeholder,
  size,
  dropShadow = false,
  shape = "circle",
}: AvatarProps) {
  const defaultAvatarSize = 24
  const classes = useStyles({ size: size || defaultAvatarSize, shape })

  return src ? (
    <DiscoImage
      alt={alt}
      src={src}
      className={classNames(classes.avatar, "avatar", className, {
        [classes.dropShadow]: dropShadow,
      })}
    />
  ) : (
    <>{placeholder}</>
  )
}

type StyleProps = {
  size: number
  shape: AvatarShape
}

const useStyles = makeUseStyles((theme) => ({
  avatar: ({ size, shape }: StyleProps) => ({
    width: size,
    height: size,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: shape === "circle" ? "50%" : "4px",
    aspectRatio: 1,
    display: "block",
  }),
  dropShadow: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export default Avatar
