import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import FileIcon from "@/core/ui/iconsax/linear/document-text.svg"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import { ChatChannelEditMessageInputQuery } from "@components/chat/channel/edit/__generated__/ChatChannelEditMessageInputQuery.graphql"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import MessageAttachContentBlockButton from "@components/chat/channel/message-input/MessageAttachContentBlockButton"
import MessageInputAutoComplete from "@components/chat/channel/message-input/MessageInputAutoComplete"
import { ChatChannelUploadsPreview } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { CHAT_CHANNEL_MAX_ROWS } from "@components/chat/channel/util/chatChannelConstants"
import { getUrlAttachments } from "@components/chat/util/chatUtils"
import { DiscoButton, DiscoTooltip } from "@disco-ui"
import classNames from "classnames"
import { ClipboardEvent } from "react"
import { FileUploadButton, ImageDropzone } from "react-file-utils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"

const ChatChannelEditMessageInput = () => {
  const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext()

  const {
    clearEditingState,
    cooldownRemaining,
    handleSubmit,
    isUploadEnabled,
    onSelectEmoji,
    maxFilesLeft,
    uploadNewFiles,
    setText,
    text,
  } = useMessageInputContext()
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<ChatChannelEditMessageInputQuery>(
    graphql`
      query ChatChannelEditMessageInputQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            products(type: "course") {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )
  const { attachments, onPaste } = useMessageInputContext()

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.root)}
        data-testid={"ChatChannelEditMessageInput.container"}
      >
        <ImageDropzone
          accept={acceptedFiles}
          disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
          handleFiles={uploadNewFiles}
          maxNumberOfFiles={maxFilesLeft}
          multiple={multipleUploads}
        >
          {/* Quote Preview */}
          {quotedMessage && (
            <ChatChannelQuotedMessagePreview quotedMessage={quotedMessage} />
          )}

          <div className={"str-chat__input-flat-wrapper"}>
            <div className={"str-chat__input-flat--textarea-wrapper"}>
              {isUploadEnabled && <ChatChannelUploadsPreview />}

              {/* Message */}
              <div className={classes.input}>
                <div className={classes.inputMessage}>
                  <MessageInputAutoComplete
                    onPaste={handlePaste}
                    rows={1}
                    maxRows={CHAT_CHANNEL_MAX_ROWS}
                    grow
                  />

                  {/* Actions */}
                  <div className={classes.inputActions}>
                    <MessageAttachContentBlockButton
                      setText={setText}
                      attachments={attachments}
                      text={text}
                      classes={classes.iconButton}
                    />
                    {/* Emoji Picker */}
                    <ChatChannelEmojiPickerDropdown
                      onEmojiSelect={onSelectEmoji}
                      classes={classes.iconButton}
                    />
                    {isUploadEnabled && !cooldownRemaining && (
                      <DiscoTooltip content={"Files"}>
                        <div data-testid={"fileinput"}>
                          <FileUploadButton
                            accepts={acceptedFiles}
                            disabled={maxFilesLeft === 0}
                            handleFiles={uploadNewFiles}
                            multiple={multipleUploads}
                          >
                            <FileIcon />
                          </FileUploadButton>
                        </div>
                      </DiscoTooltip>
                    )}
                  </div>
                </div>
                <div className={classes.buttons}>
                  {/* Cancel */}
                  <DiscoButton
                    color={"grey"}
                    variant={"outlined"}
                    onClick={clearEditingState}
                  >
                    {"Cancel"}
                  </DiscoButton>

                  {/* Save */}
                  {!cooldownRemaining && (
                    <DiscoButton onClick={handleSubmit}>{"Edit Message"}</DiscoButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ImageDropzone>
      </div>
    </div>
  )

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    onPaste(event)
    const pastedText = event.clipboardData.getData("Text")
    const products = Relay.connectionToArray(organization?.products)
    const urlAttachments = getUrlAttachments(pastedText, activeOrganization, products)
    if (urlAttachments && urlAttachments.length > 0) {
      attachments.push(...urlAttachments) // Add content attachments for pasted urls
    }

    if (urlAttachments && urlAttachments.length > 0) {
      attachments.push(...urlAttachments) // Add content attachments for pasted urls
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  root: {
    "& .rfu-file-upload-button": {
      position: "relative",
      top: "auto",
      right: "auto",
      "& svg": {
        fill: "transparent",
        color: theme.palette.groovy.grey[300],
        opacity: 1,
        width: "20px",
        height: "20px",
      },

      "& label": {
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        padding: "3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "&.str-chat__input-flat": {
      backgroundColor: theme.palette.background.paper,
      display: "list-item",
    },
    "&.str-chat__input-flat-wrapper": {
      display: "block",
    },
    "& .str-chat__input-flat--textarea-wrapper": {
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    "& .str-chat__textarea": {
      display: "flex",
      alignItems: "center",

      "& > textarea": {
        background: theme.palette.background.paper,
        padding: theme.spacing(1, 2),
        borderRadius: theme.measure.borderRadius.medium,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.background.paper}]`,
        ...theme.typography["body-sm"],

        "&:focus": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.groovy.grey
              : theme.palette.common.white,
          border: "none",
          boxShadow: `inset 0 0 0 2px ${theme.palette.groovy.grey[200]}`,
          ...theme.typography["body-sm"],
          color: theme.palette.text.secondary,
        },

        "&::placeholder": {
          ...theme.typography["body-sm"],
          color: theme.palette.groovy.grey[300],
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxHeight: "40px",
        },
      },
    },
    "& .str-chat__textarea__textarea": {
      position: "relative",
      minWidth: "auto",
      width: "calc(100% - 180px)",
      fontSize: "15px",
      lineHeight: "15px",
      border: "1px solid transparent",
      background: theme.palette.background.paper,
      overflowY: "auto",
      flexGrow: 1,
    },
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(1),
  },
  inputMessage: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
  },
  inputActions: {
    display: "flex",
    alignSelf: "flex-end",
    zIndex: theme.zIndex.raise2,
    padding: "5px",
  },
  iconButton: {
    color: theme.palette.groovy.grey[300],
    background: theme.palette.background.paper,

    "&:hover": {
      color: theme.palette.groovy.grey[500],
      background: theme.palette.background.paper,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(0.5),
  },
}))

export default ChatChannelEditMessageInput
