import { ChatChannelPageContentParams } from "@/chat/channel/page/content/ChatChannelPageContent"
import { useLabels } from "@/core/context/LabelsContext"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import Relay from "@/relay/relayUtils"
import { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import ProfileListDrawer, {
  ProfileListItemType,
} from "@/user/common/profile-list-drawer/ProfileListDrawer"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ChatChannelProfileListDrawerPaginationQuery } from "@components/chat/channel/profile-list-drawer/__generated__/ChatChannelProfileListDrawerPaginationQuery.graphql"
import { ChatChannelProfileListDrawerQuery } from "@components/chat/channel/profile-list-drawer/__generated__/ChatChannelProfileListDrawerQuery.graphql"
import { ChatChannelProfileListDrawer_PaginationFragment$key } from "@components/chat/channel/profile-list-drawer/__generated__/ChatChannelProfileListDrawer_PaginationFragment.graphql"
import { DiscoText } from "@disco-ui"
import DiscoDrawerHeaderBreadcrumbTitle from "@disco-ui/drawer/DiscoDrawerHeaderBreadcrumbTitle"
import { unionBy } from "lodash"
import { useEffect } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { useParams } from "react-router-dom"
import { graphql } from "relay-runtime"

type Props = {
  isOpen: boolean
  onClose: () => void
  channelId?: string
}

function ChatChannelProfileListDrawer({ isOpen, onClose, ...props }: Props) {
  const labels = useLabels()
  const classes = useStyles()
  const params = useParams<ChatChannelPageContentParams>()
  const channelId = params.channelId || props.channelId || ""
  const MEMBERS_PER_LOAD = 100

  const { chatChannel } = useLazyLoadQuery<ChatChannelProfileListDrawerQuery>(
    graphql`
      query ChatChannelProfileListDrawerQuery($id: ID!, $first: Int!, $after: String) {
        chatChannel: node(id: $id) {
          ... on ChatChannel {
            id
            productId
            kind
            productId
            # Always get the chatChannel admins
            chatChannelAdmins: chatChannelMembers(kind: "admin") {
              edges {
                node {
                  id
                  user {
                    id
                    first_name: firstName
                    last_name: lastName
                    avatar
                  }
                  productMembership {
                    role
                  }
                  organizationMembership {
                    role
                  }
                }
              }
            }
            app {
              customAppTitle
            }
            mgets: memberGroupEntityTargets {
              edges {
                node {
                  id
                  memberGroup {
                    ...MemberGroupTagFragment
                  }
                }
              }
            }
            ...ChatChannelProfileListDrawer_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      id: channelId,
      first: MEMBERS_PER_LOAD,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    ChatChannelProfileListDrawerPaginationQuery,
    ChatChannelProfileListDrawer_PaginationFragment$key
  >(
    graphql`
      fragment ChatChannelProfileListDrawer_PaginationFragment on ChatChannel
      @refetchable(queryName: "ChatChannelProfileListDrawerPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        chatChannelMembers(first: $first, after: $after)
          @connection(key: "ChatChannelProfileListDrawer__chatChannelMembers") {
          edges {
            node {
              id
              user {
                first_name: firstName
                last_name: lastName
                avatar
                id
              }
              productMembership {
                role
              }
              organizationMembership {
                role
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `,
    chatChannel
  )

  useEffect(() => {
    refetch(
      {
        first: MEMBERS_PER_LOAD,
      },
      { fetchPolicy: "network-only" }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!chatChannel || !data) return null

  const mgts = Relay.connectionToArray(chatChannel?.mgets)

  const membersLabel = chatChannel.productId
    ? labels.product_member.plural
    : labels.organization_member.plural

  return (
    <ProfileListDrawer
      isOpen={isOpen}
      onClose={onClose}
      users={mapChannelMembersToAvatarUsers()}
      separateAdmins
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      hasNext={hasNext}
      membersPerLoad={MEMBERS_PER_LOAD}
      totalCount={data?.chatChannelMembers.totalCount}
      title={
        <DiscoDrawerHeaderBreadcrumbTitle
          mobileIcon={"user"}
          parts={[
            membersLabel,
            chatChannel.kind === "direct_message"
              ? "Direct Message"
              : chatChannel.app?.customAppTitle || "",
          ]}
        />
      }
      contentHeader={
        mgts.length > 0 && (
          <>
            <DiscoText variant={"body-md"} marginBottom={1} color={"text.secondary"}>
              {`${membersLabel} from the following subgroup(s) can access this channel:`}
            </DiscoText>
            <div className={classes.tagsContainer}>
              {mgts.map((mgt) => (
                <MemberGroupTag key={mgt.id} memberGroupKey={mgt.memberGroup!} />
              ))}
            </div>
          </>
        )
      }
    />
  )

  function mapChannelMembersToAvatarUsers(): UserAvatarShape[] {
    const admins = Relay.connectionToArray(chatChannel?.chatChannelAdmins)
    const members = Relay.connectionToArray(data?.chatChannelMembers)

    const users: ProfileListItemType[] = []

    // Join the admin and all members arrays and remove duplicates by ID
    unionBy(admins, members, "id").forEach((member) => {
      // Filter out org owners/admins in a product w/o membership
      if (!member.productMembership && chatChannel?.productId) return

      // If the user is an owner/admin/manager/instructor, add the admin prop to the profile
      if (
        member.productMembership?.role === "manager" ||
        member.productMembership?.role === "instructor" ||
        member.organizationMembership?.role === "owner" ||
        member.organizationMembership?.role === "admin"
      ) {
        users.push({
          ...member.user,
          admin: true,
        })
      } else {
        users.push(member.user)
      }
    })

    return users
  }
}

const useStyles = makeUseStyles((theme) => ({
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: ChatChannelProfileListDrawer,
  skeleton: () => null,
})
