import ROUTE_NAMES from "@/core/route/util/routeNames"
import ChatChannelSelect from "@components/chat/channel/select/ChatChannelSelect"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { generatePath, useHistory } from "react-router-dom"
import Form from "../../form/Form"

interface NewChannelMessageModalProps {
  isOpen: boolean
  onClose: () => void
}

const NewChannelMessageModal = observer<NewChannelMessageModalProps>(
  ({ isOpen, onClose }) => {
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null)
    const history = useHistory()

    return (
      <DiscoModal
        isOpen={isOpen}
        onClose={onClose}
        modalContentLabel={"new conversation modal"}
        maxWidth={"560px"}
        title={"New Channel Message"}
        testid={"NewChannelMessageModal"}
        buttons
        body={
          <Form
            testid={"NewChannelMessageModal.Form"}
            isSubmitAllowed={Boolean(selectedChannel)}
            onSubmit={redirectToChannel}
            buttons={
              <>
                <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
                  {"Cancel"}
                </DiscoButton>

                <Form.SubmitButton
                  form={{ disabled: !selectedChannel, isSubmitting: false }}
                  testid={"NewChannelMessageModal.SubmitButton"}
                >
                  {"Start Message"}
                </Form.SubmitButton>
              </>
            }
          >
            <ChatChannelSelect
              value={selectedChannel}
              onChange={handleSelectedChatChannelsChange}
              propertyToUseForValue={"id"}
              autoFocusInput
            />
          </Form>
        }
      />
    )

    function handleSelectedChatChannelsChange(chatChannelId: string | null) {
      setSelectedChannel(chatChannelId)
    }

    function redirectToChannel() {
      if (!selectedChannel) return
      onClose()
      const channelId = selectedChannel
      // Don't redirect if we're on the chat's page already
      if (location.pathname.includes(channelId)) return
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL, {
          channelId,
        })
      )
    }
  }
)

export default NewChannelMessageModal
