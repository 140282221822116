import CommunityBadge from "@/community/CommunityBadge"
import CommunitySwitcherListItemTemplate from "@/community/switcher/CommunitySwitcherListItemTemplate"
import { CommunitySwitcherListItemFragment$key } from "@/community/switcher/__generated__/CommunitySwitcherListItemFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { switchToOrganizationDomain } from "@/core/route/util/routeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { stripURL } from "@utils/string/stringUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

interface CommunitySwitcherListItemProps {
  membershipKey: CommunitySwitcherListItemFragment$key
  testid: string
}

export default function CommunitySwitcherListItem({
  membershipKey,
  testid = "CommunitySwitcherListItem",
}: CommunitySwitcherListItemProps) {
  const { organization } = useFragment<CommunitySwitcherListItemFragment$key>(
    graphql`
      fragment CommunitySwitcherListItemFragment on OrganizationMembership {
        organization {
          id
          name
          slug
          badge {
            id
            kind
            icon
            color
            ...BadgeFragment
          }
          primaryDomain
          ...CommunityBadgeFragment
        }
      }
    `,
    membershipKey
  )

  const history = useHistory()
  const activeOrganization = useActiveOrganization()

  const isActive = organization.id === activeOrganization?.id
  const classes = useStyles()

  return (
    <CommunitySwitcherListItemTemplate
      testid={testid}
      title={organization.name}
      subtitle={stripURL(organization.primaryDomain) as string}
      isActive={isActive}
      onClick={handleNavigation}
      badge={
        <CommunityBadge
          organizationKey={organization!}
          classes={{ badge: classes.badge }}
        />
      }
    />
  )

  function handleNavigation() {
    if (isActive) {
      history.push(generatePath(ROUTE_NAMES.COMMUNITY.HOME.ROOT))
    } else {
      switchToOrganization()
    }
  }

  function switchToOrganization() {
    switchToOrganizationDomain(
      organization,
      activeOrganization,
      ROUTE_NAMES.COMMUNITY.HOME.ROOT
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  badge: {
    borderRadius: theme.measure.borderRadius.big,
    overflow: "hidden",
  },
}))
