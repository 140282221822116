import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton } from "@disco-ui"
import { useMessageInputContext } from "stream-chat-react"

function ChatChannelDetailFooterSendButton() {
  const classes = useStyles()
  const { handleSubmit, disabled } = useMessageInputContext()

  /* Show text if on mobile, or in a thread not on mobile */
  return (
    <DiscoButton
      data-testid={"ChatChannelDetailFooterSendButton.button"}
      onClick={handleSubmit}
      className={classes.iconButton}
      leftIcon={"send-01"}
      disabled={disabled}
    >
      {"Send"}
    </DiscoButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
      "& path": {
        color: theme.palette.primary.contrastText,
      },
    },
    "& path": {
      color: theme.palette.primary.light,
    },
  },
}))

export default ChatChannelDetailFooterSendButton
