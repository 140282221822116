import useDebounce from "@utils/hook/useDebounce"
import { useEffect, useState } from "react"
import { useChatContext } from "stream-chat-react"

function useStreamTotalUnreadCount(externalChannelIds: string[]): number {
  const { client: streamClient } = useChatContext()

  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  const debouncedSetTotalUnreadCount = useDebounce((count: number) => {
    setTotalUnreadCount(count)
  }, 500)

  /** Get the total unread count on load */
  useEffect(() => {
    if (!streamClient) return

    const total = _getTotalUnreadCount()
    debouncedSetTotalUnreadCount(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalChannelIds.length, debouncedSetTotalUnreadCount])

  /** Sync the total unread count when reading and receiving new message  */
  useEffect(() => {
    if (!streamClient) return
    const { unsubscribe } = streamClient.on((event) => {
      if (event.type === "message.new") {
        const total = _getTotalUnreadCount()
        debouncedSetTotalUnreadCount(total)
      }
      if (event.type === "message.read") {
        const total = _getTotalUnreadCount()
        debouncedSetTotalUnreadCount(total)
      }
    })
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamClient, externalChannelIds.length, debouncedSetTotalUnreadCount])

  function _getTotalUnreadCount() {
    if (!streamClient) return 0
    if (!streamClient.activeChannels) return 0

    /** activeChannels are the channels that are currently loaded by the stream client */
    return Object.values(streamClient.activeChannels).reduce((acc, curr) => {
      if (!curr.id) return acc
      if (externalChannelIds.includes(curr.id)) {
        return acc + curr.state.unreadCount
      }
      return acc
    }, 0)
  }

  return totalUnreadCount
}

export default useStreamTotalUnreadCount
