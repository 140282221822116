import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { CustomChatChannelFileAttachment } from "@components/chat/channel/attachment/CustomChatChannelFileAttachment"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import classNames from "classnames"
import { FileUpload, LoadingIndicator } from "stream-chat-react"

export declare type FileIconProps = {
  big?: boolean
  className?: string
  filename?: string
  mimeType?: string
  size?: number
  sizeSmall?: number
  type?: IconType
  version?: IconVersion
}

export type IconType = "standard" | "alt"
export type IconVersion = "1" | "2"

export type ChatChannelFilePreviewerProps = {
  fileIconProps?: FileIconProps
  uploads?: FileUpload[]
  handleRemove?: (id: string) => void
  handleRetry?: (id: string) => void
  handleFiles?: (files: FileList) => void
}

/** Display a list of uploaded files
 * Based off the `react-file-utils` component
 * ref: https://github.com/GetStream/react-file-utils/blob/develop/src/components/FilePreviewer.tsx
 */
export const ChatChannelFilePreviewer = ({
  uploads,
  handleRemove,
}: Partial<ChatChannelFilePreviewerProps>) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <div className={classes.container}>
      <div className={classNames(showOnHoverClasses.hoverable, classes.list)}>
        {uploads?.map((upload) => (
          <div key={upload.id} className={classes.item}>
            <CustomChatChannelFileAttachment
              name={upload.file?.name}
              url={upload.url}
              size={upload.file.size}
            />

            {upload.state === "uploading" && (
              <div className={"rfu-file-previewer__loading-indicator"}>
                <LoadingIndicator />
              </div>
            )}

            {handleRemove && (
              <ChatChannelRemoveAttachmentButton
                id={upload.id}
                handleRemove={handleRemove}
                className={showOnHoverClasses.showable}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    border: "none",
  },
  list: {
    display: "flex",
  },
  item: {
    height: "72px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.medium,
    border: `1px solid ${theme.palette.groovy.grey[200]} !important`,
    padding: theme.spacing(1.5),
    flexShrink: 0,
    position: "relative",
    margin: theme.spacing(2, 2, 0, 0),
  },
}))
