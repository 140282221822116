/**
 * @generated SignedSource<<b1f24ddfbef5e3e958a2c4a221df1100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannel_EmptyStateIndicatorFragment$data = {
  readonly id: string;
  readonly externalChannelId: string;
  readonly app: {
    readonly id: string;
    readonly customAppDescription: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSettingsModalButtonFragment">;
  } | null;
  readonly " $fragmentType": "ChatChannel_EmptyStateIndicatorFragment";
};
export type ChatChannel_EmptyStateIndicatorFragment$key = {
  readonly " $data"?: ChatChannel_EmptyStateIndicatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannel_EmptyStateIndicatorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannel_EmptyStateIndicatorFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalChannelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppDescription",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChatChannelSettingsModalButtonFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};
})();

(node as any).hash = "846251ceb201cdc55d2f8f46bd252fcc";

export default node;
