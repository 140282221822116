import discoLogo from "@/core/ui/images/automations/disco-badge.png"
import slackLogo from "@/core/ui/images/automations/slack-badge.png"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { TestIDProps } from "@utils/typeUtils"

interface ChatChannelAnchorMarkdownProps extends TestIDProps {
  href?: string
  children?: React.ReactNode
}

function ChatChannelAnchorMarkdown({ children, href }: ChatChannelAnchorMarkdownProps) {
  const classes = useStyles()

  const discoMemberGlobalId = getDiscoProfileId()

  if (discoMemberGlobalId)
    return (
      <OpenProfilePopoverButton userId={discoMemberGlobalId}>
        {(buttonProps) => (
          <DiscoText
            {...buttonProps}
            className={classes.profileButton}
            color={"groovy.blue.400"}
            display={"inline"}
          >
            {children}
          </DiscoText>
        )}
      </OpenProfilePopoverButton>
    )

  return (
    <DiscoTooltip content={getTooltipContent()}>
      <a
        className={classes.anchor}
        href={href}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        {children}
      </a>
    </DiscoTooltip>
  )

  function getDiscoProfileId() {
    try {
      const drawerProfileId = new URL(href!).searchParams.get("drawerProfileId")
      if (!drawerProfileId) return null
      return decodeURIComponent(drawerProfileId)
    } catch (e) {
      return null
    }
  }

  function getTooltipContent() {
    const linkType = getLinkType()

    switch (linkType) {
      case "slack_thread":
        return (
          <div className={classes.tooltipContent}>
            <DiscoImage className={classes.tooltipLogo} src={slackLogo} />
            <DiscoText variant={"body-xs-600"}>{"Slack Thread"}</DiscoText>
          </div>
        )
      case "slack_link":
        return (
          <div className={classes.tooltipContent}>
            <DiscoImage className={classes.tooltipLogo} src={slackLogo} />
            <DiscoText variant={"body-xs-600"}>{"Slack Link"}</DiscoText>
          </div>
        )
      case "disco_thread":
        return (
          <div className={classes.tooltipContent}>
            <DiscoImage className={classes.tooltipLogo} src={discoLogo} />
            <DiscoText variant={"body-xs-600"}>{"Disco Thread"}</DiscoText>
          </div>
        )
      default:
        return null
    }
  }

  function getLinkType() {
    if (href!.includes("drawerProfileId")) return "disco_profile"
    if (href!.match(/\/p\/.*\/chat\/.*\?streamMsgId=.*/)) return "disco_thread"
    if (href!.match(/slack.com\/archives\/.*\/p.*thread_ts=.*/)) return "slack_thread"
    if (href!.includes("slack.com/team/")) return "slack_link"
    return "other"
  }
}

const useStyles = makeUseStyles((theme) => ({
  profileButton: {
    cursor: "pointer",
    fontWeight: 700,
  },
  anchor: {
    color: theme.palette.groovy.blue[400],
    fontWeight: 500,
  },
  tooltipContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  tooltipLogo: {
    width: 24,
    height: 24,
    borderRadius: theme.measure.borderRadius.default,
  },
}))

export default ChatChannelAnchorMarkdown
