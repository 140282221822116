/**
 * @generated SignedSource<<e7149266bc64828e0e7def003e47e5d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatChannelKind = "default" | "custom" | "direct_message" | "chat_bot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DirectMessagesListItemFragment$data = {
  readonly id: string;
  readonly kind: ChatChannelKind;
  readonly externalChannelId: string;
  readonly app: {
    readonly customAppTitle: string | null;
  } | null;
  readonly chatChannelMembers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
          readonly fullName: string;
          readonly avatar: string;
        };
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "DirectMessagesListItemFragment";
};
export type DirectMessagesListItemFragment$key = {
  readonly " $data"?: DirectMessagesListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectMessagesListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectMessagesListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalChannelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeViewer",
          "value": true
        }
      ],
      "concreteType": "ChatChannelMemberNodeConnection",
      "kind": "LinkedField",
      "name": "chatChannelMembers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelMemberNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannelMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "chatChannelMembers(excludeViewer:true)"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};
})();

(node as any).hash = "53eb6f91f389bb7097e24cc8a25e0943";

export default node;
