/**
 * @generated SignedSource<<2f44ac27dd094a130f8d9f67d3f60034>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecurringEventConfirmAttendanceModalFragment$data = {
  readonly id: string;
  readonly event: {
    readonly id: string;
    readonly occurrences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isComplete: boolean;
          readonly atCapacity: boolean;
        };
      }>;
    };
  };
  readonly " $fragmentType": "RecurringEventConfirmAttendanceModalFragment";
};
export type RecurringEventConfirmAttendanceModalFragment$key = {
  readonly " $data"?: RecurringEventConfirmAttendanceModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecurringEventConfirmAttendanceModalFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecurringEventConfirmAttendanceModalFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeConnection",
          "kind": "LinkedField",
          "name": "occurrences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Occurrence",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isComplete",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "atCapacity",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "23c934953d72b5c6bc26071414499837";

export default node;
