import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import MessageIcon from "@/core/ui/iconsax/linear/message-text.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoIconButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { BaseSyntheticEvent } from "react"

export interface ChatChannelThreadHeaderProps {
  closeThread: (event: BaseSyntheticEvent) => void
}

const ChatChannelThreadHeader = ({ closeThread }: ChatChannelThreadHeaderProps) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.lhs}>
          <MessageIcon width={24} height={24} color={theme.palette.text.secondary} />
          <DiscoText variant={"body-md-600"}>{"Thread"}</DiscoText>
        </div>

        <DiscoIconButton
          onClick={closeThread}
          color={theme.palette.text.secondary}
          classes={{ root: classes.icon }}
        >
          <CloseIcon />
        </DiscoIconButton>
      </div>

      <DiscoDivider
        color={theme.palette.groovy.neutral[100]}
        thickness={2}
        marginBottom={0}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(1, 1.5, 0, 3),
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lhs: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  icon: {
    width: "40px",
    height: "40px",
    padding: theme.spacing(1),
  },
}))

export default ChatChannelThreadHeader
