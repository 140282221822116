/**
 * @generated SignedSource<<20e54ea2a913f0b234fc4687d4b8e864>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChatChannelClickedUserPopoverQuery$variables = {
  id: string;
};
export type ChatChannelClickedUserPopoverQuery$data = {
  readonly organizationMembership: {
    readonly id: string;
    readonly memberId?: string;
  } | null;
};
export type ChatChannelClickedUserPopoverQuery = {
  variables: ChatChannelClickedUserPopoverQuery$variables;
  response: ChatChannelClickedUserPopoverQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberId",
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatChannelClickedUserPopoverQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatChannelClickedUserPopoverQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f153e5c310cce20bd410e7c94246315e",
    "id": null,
    "metadata": {},
    "name": "ChatChannelClickedUserPopoverQuery",
    "operationKind": "query",
    "text": "query ChatChannelClickedUserPopoverQuery(\n  $id: ID!\n) {\n  organizationMembership: node(id: $id) {\n    __typename\n    id\n    ... on OrganizationMembership {\n      memberId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f824a6113f57b11cdb852af4cac4488";

export default node;
