import Relay from "@/relay/relayUtils"
import { useCanTriggerAIMessageSuggestionQuery } from "@components/ai/hooks/__generated__/useCanTriggerAIMessageSuggestionQuery.graphql"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { graphql, useLazyLoadQuery } from "react-relay"

function useCanTriggerAIMessageSuggestion({ channelId }: { channelId: string }): boolean {
  const hasEntitlement = useHasEntitlement("ai_inline_prompts")
  const { aiInlinePrompts } = useFeatureFlags()

  const { node } = useLazyLoadQuery<useCanTriggerAIMessageSuggestionQuery>(
    graphql`
      query useCanTriggerAIMessageSuggestionQuery($channelId: ID!) {
        node(id: $channelId) {
          ... on ChatChannel {
            id
            __typename
            viewerPermissions
          }
        }
      }
    `,
    { channelId }
  )

  if (!hasEntitlement || !aiInlinePrompts) return false
  if (!Relay.isNodeType(node, "ChatChannel")) return false
  return node.viewerPermissions.includes("chat.manage")
}

export default useCanTriggerAIMessageSuggestion
