import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton } from "@disco-ui"
import { lighten } from "@material-ui/core"
import classNames from "classnames"

export type Props = {
  id: string
  handleRemove: (id: string) => void
  className?: string
}

export const ChatChannelRemoveAttachmentButton = ({
  id,
  handleRemove,
  className: propsClasses,
}: Props) => {
  const classes = useStyles()

  return (
    <DiscoIconButton
      onClick={() => handleRemove?.(id)}
      height={24}
      width={24}
      classes={{ root: classNames(classes.delete, propsClasses) }}
      svgStyles={{ height: 15, width: 15 }}
    >
      <CloseIcon />
    </DiscoIconButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  delete: {
    borderRadius: "50%",
    backgroundColor: theme.palette.groovy.grey[500],
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translateY(-50%) translateX(50%)",

    "&:hover": {
      backgroundColor: lighten(theme.palette.groovy.grey[500], 0.2),
    },

    "& span": {
      width: "unset",

      "& svg": {
        color: theme.palette.common.white,
      },
    },
  },
}))
